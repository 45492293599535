.featured-series {
	margin: 0!important;
}

.featured-series-listing-container {


	&.row {
		margin: 0 -1.875em;
	}

	&.columns {
		&:last-child {
			float: left;
		}
	}
}

.featured-series-listing-link {
	display: block;
	text-align: center;
}

.featured-series-listing {
	display: block;

	&:hover {
		color: $brand-primary;
	}
}

.featured-series-listing-name {
	color: $brand-primary;
	@include fontSize(2.2);
	line-height: 1.1;
	display: block;
	margin: 10px 0;
	min-height: 38px;

	@media screen and (min-width: 772px) {
		// max-width: 222px;
	}
}

.featured-series-listing-image-container {
	display: inline-block;
	border: 3px solid $lightAthensGray;
	padding: 10px;
	height: 100%;
	width: 100%;

	@media screen and (min-width: 623px) {
		min-width: 222px;
		// max-height: 196px;

	}

	
	.featured-series-listing-image-wrapper {
		display: block;
		width: 100%;
		height: 172px;
		// height: 0;
		// padding-bottom: 77%;
		position: relative;

		.featured-series-listing-image {
			position: absolute;
			max-width: 100%;
			max-height: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}
}