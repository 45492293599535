//Colours for fonts - specifically carousel 
.carousel-wrapper  {
	.white {
		color: $white;
	}
	.mineShaft {
		color: $mineShaft;
	}
	//Purple
	.minsk {
		color: $minsk;
	}
	.mildGray {
		color: $mildGray;
	}
	.ripe-lemon {
		color: $ripe-lemon;
	}	
	.torchRed {
		color: $torchRed;
	}
}


//Colours for buttons - specificalyl carousel 
.red-white {
	color: $white;
	background-color: $torchRed;
}

.green-white {
	color: $white;
	background-color: $mountainMeadow2;
}

.yellow-purple {
	color: $minsk;
	background-color: $ripe-lemon;
}

.white-red {
	color: $torchRed;
	background-color: $white;
}

//Colours for Homepage Promo Panel

.whiteBackground-minskText {
	background-color: $white;

	.homepage-promo-panel-description {
		p {
			color: $minsk;
		}
	}

	.homepage-promo-panel-button-text {

		a {
			color: $white;
			background-color: $torchRed;
		}

		&:after {
			color: $white;
		}
	}
}

.paleGrayBackground-blackText {
	background-color: $paleGray;

	.homepage-promo-panel-description {
		p {
			color: $black;
		}
	}

	.homepage-promo-panel-button-text {
		
		a {
			color: $white;
			background-color: $java;
		}

		&:after {
			color: $white;
		}
	}
}

.whiteBackground-mildGrayText {
	background-color: $white;

	.homepage-promo-panel-description {
		p{
			color: $mildGray;	
		}
	}

	.homepage-promo-panel-button-text {
		a {
			color: $minsk;
			background-color: $lemon;
		}

		&:after {
			color: $minsk;
		}
	}	
}

.minskBackground-whiteText {
	background-color: $minsk;
	
	.homepage-promo-panel-description {
		p {
			color: $white;
		}
	}
	
	.homepage-promo-panel-button-text {
		a {		
			background-color: $white;
			color: $torchRed;
		}

		&:after {
			color: $torchRed;
		}
	}
}

.emperorBackground-lemonText {

	background-color: $emperor;

	.homepage-promo-panel-description {
		p {
			color: $lemon;
		}
	}

	.homepage-promo-panel-button-text {
		a {
			background-color: $white;
			color: $torchRed;
		}

		&:after {
			color: $torchRed;
		}
	}
}


.blackBackground-whiteText {
	background-color: $black;
	.homepage-promo-panel-description {
		p {
			color: $white;
		}
	}

	.homepage-promo-panel-button-text {
		a {
			color: $minsk;
			background-color: $lemon;
		}

		&:after {
			color: $minsk;
		}
	}
}

.javaBackground-whiteText {
	background-color: $java;

	.homepage-promo-panel-description {
		p {
			color: $white;
		}
	}

	.homepage-promo-panel-button-text {
		a {
			color: $torchRed;
			background-color: $white;
		}

		&:after {
			color: $torchRed;
		}
	}

}