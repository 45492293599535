em {
	font-style: italic;
}

// blockquote {
// 	margin: 0px;
// 	border-left: 0 none;
// 	position: relative;
// 	padding-top: 10px;
// 	padding-bottom: 15px;
// 	padding-left: 44px;
// 	padding-right: 44px;

// 	&:before,
//     &:after {
//         display: inline-block;
//         color: #F90E46;
//         font-size: 84px;
//         font-family: 'helvetic';
//         position: absolute;
//     }

//     &:before {
//         content: '\201C';
// 		top: -20px;
// 		left: -4px;
//     }

//     &:after {
//         content: '\201D';
//         bottom: -33px;
//         right: 0;
//     }

//     p {
//     	font-size: 24px;
// 		line-height: 21px;
// 		font-style: italic;
// 		color: $minsk;
// 		font-family: helvetic;
//     }
// }

// .series-page blockquote p {
// 	font-size: 24px;
// 	line-height: 21px;
// 	font-style: italic;
// 	color: $minsk;
// }