.homepage-promo-panel {
	width: 33%;
	margin-top: 18px;
}
.side-widget-promo-panel {
	width: 100%;
	margin-top: 10px;
}

.homepage-promo-panel, .side-widget-promo-panel {
	display: inline-block;
	vertical-align: top;
}

.homepage-promo-panel-wrapper {

	padding: 10px;
	text-align: center;

	@media screen and (max-width: 768px) {
		display: none;
	}
	
	.side-widget-promo-panel {
		vertical-align: top;
		display: inline-block;
		width: 100%;
		padding: 10px;
		text-align: center;
	}

	.homepage-promo-panel-image {
		-moz-transform: rotate(-7deg);
		-webkit-transform: rotate(-7deg);
		-o-transform: rotate(-7deg);
		transform: rotate(-7deg);

		padding-top: 24px;
		padding-bottom: 49px;
		 img {
		 	background: transparent;
		}
	}

	.homepage-promo-panel-description {
		p {
			font-size: 21px;
			font-weight: bold;
		}
	}

	.homepage-promo-panel-button-text {
		a {
			-webkit-border-radius: 16;
		-moz-border-radius: 16;
		border-radius: 16px;
		padding: 10px 31px 10px 31px;
		margin-top: 22px;
		margin-bottom: 26px;
		border: none;
		min-height: 38px;
		font-weight: bold;
		display: inline-block;
		}
		

		// &:after {
		// 	padding-left: 0.31em;
		// 	content: '\000BB';
		// 	display: inline-block;
		// 	font-size: 15px;
		// }
	}
}