body { 
	font-size:62.5%; 
}

h1 {
	@include fontSize(3.4);
}

h2 {
	@include fontSize(2.8);
}

h3 {
	@include fontSize(2.2);
}

h4 {
	@include fontSize(2.0);
}

h5 {
	@include fontSize(1.8);
}

h6 {
	@include fontSize(1.5);
}

h1, h2, h3, h4, h5, h6 {
	a {
		font-size: inherit;
	}
}

p, span {
	@include fontSize(1.5);
}

a {
	@include fontSize(1.5);	
}
