.hv-bestselling-resources-wrapper {
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 42px;
    text-align: justify;
    letter-spacing: -0.31em;

    > * {
        letter-spacing: normal;
    }
    .hv-featured-products-header {
        p {
            margin-bottom: 29px;
        }
    }
    a.item-listing {
        max-width: 221px;
    }
    .remove {
        display: none;
    }
    .large-8 {
        text-align: center;
        width: 25%;
        min-height: 265px;
        padding-bottom: 7px;
        padding-left: 0;
        @media screen and (max-width: 748px) and (min-width: 412px) {
            min-height: 0px;
        }
        @media screen and (max-width: 413px) and (min-width: 380px) {
            min-height: 265px;
        }
        @media screen and (max-width: 380px) {
            min-height: 27px;
        }

        
        
        .item-listing-image-container {
            max-width: 221px;
            background-color: $white;
    		display: inline-block;
    		margin-bottom: 9px;
        }
        .item-listing-name {
            clear: both;
            text-align: left;
            font-size: 1.7rem;
            font-weight: 400;
            margin: 0;
            color: $mineShaft;
        }
        @media screen and (max-width: $mediumMinWidthFoundationBreak) {
            width: 50%;
            .item-listing-name {
                text-align: center;
            }
        }
    }
    .footer {
        clear: both;
        text-align: center;
        margin-bottom: 56px;
    }
}
