@font-face {
  font-family: 'Oxygen';
  src: url("~@fonts/oxygen-bold.eot");
  src: url("~@fonts/oxygen-bold.eot?#iefix") format("embedded-opentype"),
    url("~@fonts/oxygen-bold.woff") format("woff"),
    url("~@fonts/oxygen-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen';
  src: url("~@fonts/oxygen-light.eot");
  src: url("~@fonts/oxygen-light.eot?#iefix") format("embedded-opentype"),
    url("~@fonts/oxygen-light.woff") format("woff"),
    url("~@fonts/oxygen-light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen';
  src: url("~@fonts/oxygen-regular.eot");
  src: url("~@fonts/oxygen-regular.eot?#iefix") format("embedded-opentype"),
    url("~@fonts/oxygen-regular.woff") format("woff"),
    url("~@fonts/oxygen-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: 'Oxygen', sans-serif;
  font-size: 62.5%;
}
