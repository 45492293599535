//
// Appearance
// -----------------

@mixin boxShadow($horizontalOffset: 1px, $verticalOffset: 1px, $blur: 1px, $spread: 1px, $colour: rgba(50, 50, 50, 1), $inset: false) {
  @if $inset {
    box-shadow: inset $horizontalOffset $verticalOffset $blur $spread $colour;
    -webkit-box-shadow: inset $horizontalOffset $verticalOffset $blur $spread $colour;
    -moz-box-shadow: inset $horizontalOffset $verticalOffset $blur $spread $colour;
  }

  @else {
    box-shadow: $horizontalOffset $verticalOffset $blur $spread $colour;
    -webkit-box-shadow: $horizontalOffset $verticalOffset $blur $spread $colour;
    -moz-box-shadow: $horizontalOffset $verticalOffset $blur $spread $colour;
  }
}

@mixin letterpress($opacity) {
  text-shadow: white($opacity) 0 1px 0;
}

@mixin borderRadius($topLeft: 5px, $topRight: 5px, $bottomRight: 5px, $bottomLeft: 5px) {
  -moz-border-radius: $topLeft $topRight $bottomRight $bottomLeft;
  -webkit-border-top-left-radius: $topLeft;
  -webkit-border-top-right-radius: $topRight;
  -webkit-border-bottom-right-radius: $bottomRight;
  -webkit-border-bottom-left-radius: $bottomLeft;
  border-radius: $topLeft $topRight $bottomRight $bottomLeft;
}

@mixin borderRadiusIdentical($radius: 5px) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin bootstrapButton($startColour: #0088cc, $endColour: #0044cc, $fontColour: #fff) {
  background-color: hsl(hue($startColour), 100, 30) !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$startColour}, endColorstr=#{$endColour});
  background-image: -khtml-gradient(linear, left top, left bottom, from($startColour), to($endColour));
  background-image: -moz-linear-gradient(top, $startColour, $endColour);
  background-image: -ms-linear-gradient(top, $startColour, $endColour);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $startColour), color-stop(100%, $endColour));
  background-image: -webkit-linear-gradient(top, $startColour, $endColour);
  background-image: -o-linear-gradient(top, $startColour, $endColour);
  background-image: linear-gradient($startColour, $endColour);
  border-color: $endColour $endColour darken($startColour, 20%);
  color: $fontColour;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.33);
  -webkit-font-smoothing: antialiased;
}

@mixin opacity($value: 1) {
  $opacityIE: $value * 100;
  opacity: $value;
  filter: alpha(opacity=$opacityIE);
  -ms-filter: "alpha(opacity="+ $value + ")";
  -khtml-opacity: $value;
  -moz-opacity: $value;
  zoom: 1;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}

//
// Utilities
// -----------------
@mixin boxSizing() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// http://stackoverflow.com/questions/3461441/prevent-flicker-on-webkit-transition-of-webkit-transform
@mixin fixWebkitFlicker() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin responsiveImg($display: block) {
  display: $display;
  max-width: 100%;
  height: auto;
}

// http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin fixIEInlineBlock {
  display: inline-block;
  *display: inline;
  zoom: 1;
}

//
// Transitions & Transformations
// -----------------
@mixin transition($area: all, $duration: 2s, $type: linear) {
  -webkit-transition: $area $duration $type;
  -moz-transition: $area $duration $type;
  -ms-transition: $area $duration $type;
  -o-transition: $area $duration $type;
  transition: $area $duration $type;
}

@mixin transformRotate($degrees: 45deg) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
}

@mixin transformScale($x: 1, $y: 1) {
  -webkit-transform: scale($x, $y);
  -moz-transform: scale($x, $y);
  -ms-transform: scale($x, $y);
  -o-transform: scale($x, $y);
}

@mixin transformTranslate($x: 1px, $y: 1px) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
}

@mixin fontFace($fontName, $fontPath, $fontFileName) {
  font-family: '#{$fontName}';
  src: url('#{$fontPath}/#{$fontFileName}.eot');
  src: url('#{$fontPath}/#{$fontFileName}.eot?#iefix') format('embedded-opentype'),
  url('#{$fontPath}/#{$fontFileName}.woff') format('woff'),
  url('#{$fontPath}/#{$fontFileName}.ttf');
}

@mixin fontSize($value: 1.6, $important: '') {
  font-size: ($value * 10)+px #{$important};
  font-size: $value+rem #{$important};
}

@mixin icon($content, $font-size, $color) {
  content: $content;
  font-family: 'rs-icons';
  font-size: $font-size;
  color: $color;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin verticalAlignmentFix($alignment) {

  //The Ghost fix from https://css-tricks.com/centering-in-the-unknown/
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: $alignment;
  }
}

@mixin letterTracking($tracking: 1000) {
  letter-spacing: (1em *($tracking / 1000));
}

@mixin leadingToLineHeight($fontSize, $leading: null) {

  @if $leading !=null {
    line-height: 1px *($fontSize + ($leading / 2));
  }

  @else {
    line-height: 1px *($fontSize * 1.2);
  }
}

@mixin justifyLayout() {
  text-align: justify;
  letter-spacing: -0.31em;

  >* {
    display: inline-block;
    letter-spacing: normal;

  }

  &:after {
    display: inline-block;
    width: 100%;
    content: '';
  }
}

/*
 * This is taken from http://mystrd.at/tough-fucker-the-ultimate-css2-vertical-centering-monster/
 * When using this, the element you actually want to center must have the following styles:
 * 
 *   display: inline-block;
 *   vertical-align: middle;
 *   letter-spacing: normal;
 *   font-size: 16px; // overpower the parent font-size: 0
 *
 */
@mixin toughFucker() {
  font-size: 0;
  /* kill the inline-block white-space */
  letter-spacing: -1px;
  /* http://stackoverflow.com/a/6351697/759144 */

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    padding: 0;
  }
}
