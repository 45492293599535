.free-stuff-list-cont {
	&.free-stuff-list-cont-subject-page {
		min-height: none!important;
		display: inline-block;
		width: 50%;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 20px;
		@media screen and (max-width: 768px) {
			width: auto;
			display: block;
			min-height: 378px;
		}
		.cta-get-more-free-stuff {
			display:none;
		}
	}

	&.free-stuff-list-cont-home {
		min-height: 472px;
		display: inline-block;
		width: 33%;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 20px;
		@media screen and (max-width: 768px) {
			width: 100%;
			min-height: 0;
		}
	}

	h2 {
		border-bottom: 1px solid $minsk;
		padding-bottom: 11px;
		font-size: 2.8rem;
		text-align: left;
	}

	.free-stuff-list {
		padding-top: 10px;
		@media screen and (max-width: 768px) {
			padding-bottom: 20px;
		}
	}

	.cta-get-more-free-stuff {
		position: absolute;
		bottom: 10px;
		font-weight: bold;
		&:after {
			padding-left: 0.31em;
			content: '\000BB';
			display: inline-block;
		}

		@media screen and (max-width: 768px) {
			position: static;
		}
	}
}

a.free-stuff-page-widget-listing {
	display: block;
	vertical-align: top;
	min-height: 84px;
	position: relative;
	padding-left: 49px;
	padding-bottom: 10px; 
	padding-right: 5px;
    padding-top: 10px;

	.free-stuff-page-icon-cont {
		position: absolute;
		left: 0;
		text-align: right;
	}

	.free-stuff-page-text-cont {
		display: block;

		.free-stuff-page-title {
			text-align: left;
			display: inline-block;
			line-height: 1.4;
			font-size: 14pt;
			font-size: 1.4rem;
			color: $brand-primary;
			font-weight: bold;
		}
	}

	.free-stuff-page-cta {
		display: block;
		padding-top: 7px;
		color: #ff0048;
		font-family: 'Oxygen', sans-serif;

		&:after {
			padding-left: 0.31em;
			content: '\000BB';
			display: inline-block;
		}
	}

	&:hover {
		.free-stuff-page-cta {
			text-decoration: underline;
		}
	}

	img {
		width: 36px;
	}
}

