.banner {
  height: 284px;
}

.banner,
.home-page-banner-area {
  display: none;

  @media screen and (max-width: $mediumMinWidthFoundationBreak) {
    height: auto;
  }

  .container {
    height: 100%;

    iframe {
      width: 100%;
      height: 530px;
    }

    .columns {
      height: 100%;
      position: relative;
      padding-left: 5.9375em;
      padding-right: 5.9375em;

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        height: auto;
        padding: 0 0.9375em;
      }
    }
  }

  .banner-logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin: 20px auto;
    }
  }

  .banner-title-wrapper {
    height: 248px;
    display: table-cell;
    margin: auto;
    vertical-align: middle;
    text-align: left;

    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
      height: auto;
      display: block;
      margin: 20px auto;
    }

    .banner-title {
      @include fontSize(3.2);
      line-height: 1.35;
      text-shadow: 0px 1px 1px $black;
      color: $white;
    }
  }
}

.home-page-banner-area {
  overflow: hidden;
  display: block;
  min-height: 330px;
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px;
  background-size: 54px 23px, cover;
  background-color: #006d77;

  .container {
    .columns {
      padding: 0 0.9375em;
    }
  }
}

.banner {
  margin-bottom: 35px;

  &--no-margin {
    margin: 0;
  }
}

.banner.series-landing {
  @media screen and (max-width: 800px) {
    height: auto;
  }

  .columns.medium-13:nth-child(3) {
    .banner-products-button-wrapper {
      height: 248px;
      display: table-cell;
      margin: auto;
      vertical-align: middle;
      width: 180px;
      min-width: 180px;

      .view-all-products-button {
        display: inline-block;
        padding: 10px 22px;
        margin-bottom: 0;
        @include fontSize(1.5);
        font-weight: bold;
        line-height: 1.6;
        text-align: center;
        vertical-align: middle;
        color: $white;
        background-color: $torchRed;
        border-radius: 25px;

        @media screen and (max-width: $mediumMinWidthFoundationBreak) {
          margin-top: 0px;
        }

        &:after {
          content: '\00bb';
          @include fontSize(1.5);
          font-weight: bold;
          line-height: 1.6;
        }
      }
    }

    @media screen and (max-width: 800px) {
      padding: 20px 0px 20px 0px;
      width: 100%;

      .banner-products-button-wrapper {
        height: auto;
        display: table;
      }
    }
  }

  .columns.medium-13:nth-child(1),
  .columns.medium-22:nth-child(2) {
    @media screen and (max-width: 800px) {
      height: 248px;
    }

    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
      height: auto;
    }
  }
}

.banner-title {
  text-align: center;
  padding-top: 75px;
  padding-bottom: 75px;

  h1,
  h2 {
    font-size: 40px;
    line-height: 45px;
    color: white;
  }

  h1 {
    font-weight: bold;
  }

  .btn {
    margin-top: 20px;
  }
}

.banner-content {
  background-repeat: no-repeat;
  background-size: cover;

  & &__inner {
    padding: 40px 0;
    display: flex;
    justify-content: space-evenly;

    .content-left {
      background-color: #25134c;
      padding: 15px;
      flex: 0 0 45%;

      > *:first-child {
        margin: 0 !important;
        font-size: 35px;
        line-height: 40px;
        color: white;
      }

      @media (max-width: 959px) {
        max-width: 60%;
        margin-bottom: 10px;
        align-self: center;
      }

      @media (max-width: 699px) {
        max-width: 80%;
      }
    }

    .content-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      vertical-align: middle;
      align-items: center;
      flex: 0 0 20%;

      &--inner {
        padding: 5px;
        background-color: white;
      }

      @media (max-width: 959px) {
        max-width: 30%;
        align-self: center;
      }

      @media (max-width: 699px) {
        max-width: 50%;
      }
    }

    @media (max-width: 959px) {
      flex-direction: column;
    }
  }
}
