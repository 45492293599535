//Modified from http://foundation.zurb.com/ 

html {
box-sizing: border-box; }

*,
*:before,
*:after {
-webkit-box-sizing: inherit;
-moz-box-sizing: inherit;
box-sizing: inherit; }

a:hover {
cursor: pointer; }

img {
max-width: 100%;
height: auto; }


ul {
list-style: initial;
list-style-type: disc;
line-height: 23px;
color: $mineShaft;
@include fontSize(1.5);
margin-bottom: 10px;
padding-left: 20px;
  li {
    display: list-item;}}

// table {
//   width: 100%!important;
//   margin-bottom: 20px;
//   p {
//     margin: 0px;}
//   td {
//     border: 1px solid black;
//     padding: 8px;}}

img {
-ms-interpolation-mode: bicubic; }

// #map_canvas img,
// #map_canvas embed,
// #map_canvas object,
// .map_canvas img,
// .map_canvas embed,
// .map_canvas object,
// .mqa-display img,
// .mqa-display embed,
// .mqa-display object {
//   max-width: none !important; }

.left {
float: left !important; }

.right {
float: right !important; }

.clearfix:before, .clearfix:after {
content: " ";
display: table; }
.clearfix:after {
clear: both; }

.hide {
display: none; }

.hidden {
    display: none !important;
}

.invisible {
visibility: hidden; }

.antialiased {
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; }

img {
display: inline-block;
vertical-align: middle; }

.row {
  margin: 0 auto;
  max-width: 62.5em;
  width: 100%; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row.collapse > .column,
  .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row .row {
    margin: 0 -0.9375em;
    max-width: none;
    width: auto; }
    .row .row:before, .row .row:after {
      content: " ";
      display: table; }
    .row .row:after {
      clear: both; }
    .row .row.collapse {
      margin: 0;
      max-width: none;
      width: auto; }
      .row .row.collapse:before, .row .row.collapse:after {
        content: " ";
        display: table; }
      .row .row.collapse:after {
        clear: both; }

.column,
.columns {
  padding-left: 0.9375em;
  padding-right: 0.9375em;
  width: 100%;
  float: left; }

.column + .column:last-child,
.columns + .column:last-child, .column +
.columns:last-child,
.columns +
.columns:last-child {
  float: right; }
.column + .column.end,
.columns + .column.end, .column +
.columns.end,
.columns +
.columns.end {
  float: left; }

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0;
    right: auto; }

  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto; }

  .small-push-1 {
    position: relative;
    left: 2.08333%;
    right: auto; }

  .small-pull-1 {
    position: relative;
    right: 2.08333%;
    left: auto; }

  .small-push-2 {
    position: relative;
    left: 4.16667%;
    right: auto; }

  .small-pull-2 {
    position: relative;
    right: 4.16667%;
    left: auto; }

  .small-push-3 {
    position: relative;
    left: 6.25%;
    right: auto; }

  .small-pull-3 {
    position: relative;
    right: 6.25%;
    left: auto; }

  .small-push-4 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .small-pull-4 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .small-push-5 {
    position: relative;
    left: 10.41667%;
    right: auto; }

  .small-pull-5 {
    position: relative;
    right: 10.41667%;
    left: auto; }

  .small-push-6 {
    position: relative;
    left: 12.5%;
    right: auto; }

  .small-pull-6 {
    position: relative;
    right: 12.5%;
    left: auto; }

  .small-push-7 {
    position: relative;
    left: 14.58333%;
    right: auto; }

  .small-pull-7 {
    position: relative;
    right: 14.58333%;
    left: auto; }

  .small-push-8 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .small-pull-8 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .small-push-9 {
    position: relative;
    left: 18.75%;
    right: auto; }

  .small-pull-9 {
    position: relative;
    right: 18.75%;
    left: auto; }

  .small-push-10 {
    position: relative;
    left: 20.83333%;
    right: auto; }

  .small-pull-10 {
    position: relative;
    right: 20.83333%;
    left: auto; }

  .small-push-11 {
    position: relative;
    left: 22.91667%;
    right: auto; }

  .small-pull-11 {
    position: relative;
    right: 22.91667%;
    left: auto; }

  .small-push-12 {
    position: relative;
    left: 25%;
    right: auto; }

  .small-pull-12 {
    position: relative;
    right: 25%;
    left: auto; }

  .small-push-13 {
    position: relative;
    left: 27.08333%;
    right: auto; }

  .small-pull-13 {
    position: relative;
    right: 27.08333%;
    left: auto; }

  .small-push-14 {
    position: relative;
    left: 29.16667%;
    right: auto; }

  .small-pull-14 {
    position: relative;
    right: 29.16667%;
    left: auto; }

  .small-push-15 {
    position: relative;
    left: 31.25%;
    right: auto; }

  .small-pull-15 {
    position: relative;
    right: 31.25%;
    left: auto; }

  .small-push-16 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .small-pull-16 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .small-push-17 {
    position: relative;
    left: 35.41667%;
    right: auto; }

  .small-pull-17 {
    position: relative;
    right: 35.41667%;
    left: auto; }

  .small-push-18 {
    position: relative;
    left: 37.5%;
    right: auto; }

  .small-pull-18 {
    position: relative;
    right: 37.5%;
    left: auto; }

  .small-push-19 {
    position: relative;
    left: 39.58333%;
    right: auto; }

  .small-pull-19 {
    position: relative;
    right: 39.58333%;
    left: auto; }

  .small-push-20 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .small-pull-20 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .small-push-21 {
    position: relative;
    left: 43.75%;
    right: auto; }

  .small-pull-21 {
    position: relative;
    right: 43.75%;
    left: auto; }

  .small-push-22 {
    position: relative;
    left: 45.83333%;
    right: auto; }

  .small-pull-22 {
    position: relative;
    right: 45.83333%;
    left: auto; }

  .small-push-23 {
    position: relative;
    left: 47.91667%;
    right: auto; }

  .small-pull-23 {
    position: relative;
    right: 47.91667%;
    left: auto; }

  .small-push-24 {
    position: relative;
    left: 50%;
    right: auto; }

  .small-pull-24 {
    position: relative;
    right: 50%;
    left: auto; }

  .small-push-25 {
    position: relative;
    left: 52.08333%;
    right: auto; }

  .small-pull-25 {
    position: relative;
    right: 52.08333%;
    left: auto; }

  .small-push-26 {
    position: relative;
    left: 54.16667%;
    right: auto; }

  .small-pull-26 {
    position: relative;
    right: 54.16667%;
    left: auto; }

  .small-push-27 {
    position: relative;
    left: 56.25%;
    right: auto; }

  .small-pull-27 {
    position: relative;
    right: 56.25%;
    left: auto; }

  .small-push-28 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .small-pull-28 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .small-push-29 {
    position: relative;
    left: 60.41667%;
    right: auto; }

  .small-pull-29 {
    position: relative;
    right: 60.41667%;
    left: auto; }

  .small-push-30 {
    position: relative;
    left: 62.5%;
    right: auto; }

  .small-pull-30 {
    position: relative;
    right: 62.5%;
    left: auto; }

  .small-push-31 {
    position: relative;
    left: 64.58333%;
    right: auto; }

  .small-pull-31 {
    position: relative;
    right: 64.58333%;
    left: auto; }

  .small-push-32 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .small-pull-32 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .small-push-33 {
    position: relative;
    left: 68.75%;
    right: auto; }

  .small-pull-33 {
    position: relative;
    right: 68.75%;
    left: auto; }

  .small-push-34 {
    position: relative;
    left: 70.83333%;
    right: auto; }

  .small-pull-34 {
    position: relative;
    right: 70.83333%;
    left: auto; }

  .small-push-35 {
    position: relative;
    left: 72.91667%;
    right: auto; }

  .small-pull-35 {
    position: relative;
    right: 72.91667%;
    left: auto; }

  .small-push-36 {
    position: relative;
    left: 75%;
    right: auto; }

  .small-pull-36 {
    position: relative;
    right: 75%;
    left: auto; }

  .small-push-37 {
    position: relative;
    left: 77.08333%;
    right: auto; }

  .small-pull-37 {
    position: relative;
    right: 77.08333%;
    left: auto; }

  .small-push-38 {
    position: relative;
    left: 79.16667%;
    right: auto; }

  .small-pull-38 {
    position: relative;
    right: 79.16667%;
    left: auto; }

  .small-push-39 {
    position: relative;
    left: 81.25%;
    right: auto; }

  .small-pull-39 {
    position: relative;
    right: 81.25%;
    left: auto; }

  .small-push-40 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .small-pull-40 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .small-push-41 {
    position: relative;
    left: 85.41667%;
    right: auto; }

  .small-pull-41 {
    position: relative;
    right: 85.41667%;
    left: auto; }

  .small-push-42 {
    position: relative;
    left: 87.5%;
    right: auto; }

  .small-pull-42 {
    position: relative;
    right: 87.5%;
    left: auto; }

  .small-push-43 {
    position: relative;
    left: 89.58333%;
    right: auto; }

  .small-pull-43 {
    position: relative;
    right: 89.58333%;
    left: auto; }

  .small-push-44 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .small-pull-44 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .small-push-45 {
    position: relative;
    left: 93.75%;
    right: auto; }

  .small-pull-45 {
    position: relative;
    right: 93.75%;
    left: auto; }

  .small-push-46 {
    position: relative;
    left: 95.83333%;
    right: auto; }

  .small-pull-46 {
    position: relative;
    right: 95.83333%;
    left: auto; }

  .small-push-47 {
    position: relative;
    left: 97.91667%;
    right: auto; }

  .small-pull-47 {
    position: relative;
    right: 97.91667%;
    left: auto; }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .small-1 {
    width: 2.08333%; }

  .small-2 {
    width: 4.16667%; }

  .small-3 {
    width: 6.25%; }

  .small-4 {
    width: 8.33333%; }

  .small-5 {
    width: 10.41667%; }

  .small-6 {
    width: 12.5%; }

  .small-7 {
    width: 14.58333%; }

  .small-8 {
    width: 16.66667%; }

  .small-9 {
    width: 18.75%; }

  .small-10 {
    width: 20.83333%; }

  .small-11 {
    width: 22.91667%; }

  .small-12 {
    width: 25%; }

  .small-13 {
    width: 27.08333%; }

  .small-14 {
    width: 29.16667%; }

  .small-15 {
    width: 31.25%; }

  .small-16 {
    width: 33.33333%; }

  .small-17 {
    width: 35.41667%; }

  .small-18 {
    width: 37.5%; }

  .small-19 {
    width: 39.58333%; }

  .small-20 {
    width: 41.66667%; }

  .small-21 {
    width: 43.75%; }

  .small-22 {
    width: 45.83333%; }

  .small-23 {
    width: 47.91667%; }

  .small-24 {
    width: 50%; }

  .small-25 {
    width: 52.08333%; }

  .small-26 {
    width: 54.16667%; }

  .small-27 {
    width: 56.25%; }

  .small-28 {
    width: 58.33333%; }

  .small-29 {
    width: 60.41667%; }

  .small-30 {
    width: 62.5%; }

  .small-31 {
    width: 64.58333%; }

  .small-32 {
    width: 66.66667%; }

  .small-33 {
    width: 68.75%; }

  .small-34 {
    width: 70.83333%; }

  .small-35 {
    width: 72.91667%; }

  .small-36 {
    width: 75%; }

  .small-37 {
    width: 77.08333%; }

  .small-38 {
    width: 79.16667%; }

  .small-39 {
    width: 81.25%; }

  .small-40 {
    width: 83.33333%; }

  .small-41 {
    width: 85.41667%; }

  .small-42 {
    width: 87.5%; }

  .small-43 {
    width: 89.58333%; }

  .small-44 {
    width: 91.66667%; }

  .small-45 {
    width: 93.75%; }

  .small-46 {
    width: 95.83333%; }

  .small-47 {
    width: 97.91667%; }

  .small-48 {
    width: 100%; }

  .small-offset-0 {
    margin-left: 0 !important; }

  .small-offset-1 {
    margin-left: 2.08333% !important; }

  .small-offset-2 {
    margin-left: 4.16667% !important; }

  .small-offset-3 {
    margin-left: 6.25% !important; }

  .small-offset-4 {
    margin-left: 8.33333% !important; }

  .small-offset-5 {
    margin-left: 10.41667% !important; }

  .small-offset-6 {
    margin-left: 12.5% !important; }

  .small-offset-7 {
    margin-left: 14.58333% !important; }

  .small-offset-8 {
    margin-left: 16.66667% !important; }

  .small-offset-9 {
    margin-left: 18.75% !important; }

  .small-offset-10 {
    margin-left: 20.83333% !important; }

  .small-offset-11 {
    margin-left: 22.91667% !important; }

  .small-offset-12 {
    margin-left: 25% !important; }

  .small-offset-13 {
    margin-left: 27.08333% !important; }

  .small-offset-14 {
    margin-left: 29.16667% !important; }

  .small-offset-15 {
    margin-left: 31.25% !important; }

  .small-offset-16 {
    margin-left: 33.33333% !important; }

  .small-offset-17 {
    margin-left: 35.41667% !important; }

  .small-offset-18 {
    margin-left: 37.5% !important; }

  .small-offset-19 {
    margin-left: 39.58333% !important; }

  .small-offset-20 {
    margin-left: 41.66667% !important; }

  .small-offset-21 {
    margin-left: 43.75% !important; }

  .small-offset-22 {
    margin-left: 45.83333% !important; }

  .small-offset-23 {
    margin-left: 47.91667% !important; }

  .small-offset-24 {
    margin-left: 50% !important; }

  .small-offset-25 {
    margin-left: 52.08333% !important; }

  .small-offset-26 {
    margin-left: 54.16667% !important; }

  .small-offset-27 {
    margin-left: 56.25% !important; }

  .small-offset-28 {
    margin-left: 58.33333% !important; }

  .small-offset-29 {
    margin-left: 60.41667% !important; }

  .small-offset-30 {
    margin-left: 62.5% !important; }

  .small-offset-31 {
    margin-left: 64.58333% !important; }

  .small-offset-32 {
    margin-left: 66.66667% !important; }

  .small-offset-33 {
    margin-left: 68.75% !important; }

  .small-offset-34 {
    margin-left: 70.83333% !important; }

  .small-offset-35 {
    margin-left: 72.91667% !important; }

  .small-offset-36 {
    margin-left: 75% !important; }

  .small-offset-37 {
    margin-left: 77.08333% !important; }

  .small-offset-38 {
    margin-left: 79.16667% !important; }

  .small-offset-39 {
    margin-left: 81.25% !important; }

  .small-offset-40 {
    margin-left: 83.33333% !important; }

  .small-offset-41 {
    margin-left: 85.41667% !important; }

  .small-offset-42 {
    margin-left: 87.5% !important; }

  .small-offset-43 {
    margin-left: 89.58333% !important; }

  .small-offset-44 {
    margin-left: 91.66667% !important; }

  .small-offset-45 {
    margin-left: 93.75% !important; }

  .small-offset-46 {
    margin-left: 95.83333% !important; }

  .small-offset-47 {
    margin-left: 97.91667% !important; }

  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }

  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }

  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }

  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none; }

  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left; }

  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right; }

  .row.small-collapse > .column,
  .row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.small-uncollapse > .column,
  .row.small-uncollapse > .columns {
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; } }
@media only screen and (min-width: $mediumMinWidthFoundationBreak) {
  .medium-push-0 {
    position: relative;
    left: 0;
    right: auto; }

  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto; }

  .medium-push-1 {
    position: relative;
    left: 2.08333%;
    right: auto; }

  .medium-pull-1 {
    position: relative;
    right: 2.08333%;
    left: auto; }

  .medium-push-2 {
    position: relative;
    left: 4.16667%;
    right: auto; }

  .medium-pull-2 {
    position: relative;
    right: 4.16667%;
    left: auto; }

  .medium-push-3 {
    position: relative;
    left: 6.25%;
    right: auto; }

  .medium-pull-3 {
    position: relative;
    right: 6.25%;
    left: auto; }

  .medium-push-4 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .medium-pull-4 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .medium-push-5 {
    position: relative;
    left: 10.41667%;
    right: auto; }

  .medium-pull-5 {
    position: relative;
    right: 10.41667%;
    left: auto; }

  .medium-push-6 {
    position: relative;
    left: 12.5%;
    right: auto; }

  .medium-pull-6 {
    position: relative;
    right: 12.5%;
    left: auto; }

  .medium-push-7 {
    position: relative;
    left: 14.58333%;
    right: auto; }

  .medium-pull-7 {
    position: relative;
    right: 14.58333%;
    left: auto; }

  .medium-push-8 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .medium-pull-8 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .medium-push-9 {
    position: relative;
    left: 18.75%;
    right: auto; }

  .medium-pull-9 {
    position: relative;
    right: 18.75%;
    left: auto; }

  .medium-push-10 {
    position: relative;
    left: 20.83333%;
    right: auto; }

  .medium-pull-10 {
    position: relative;
    right: 20.83333%;
    left: auto; }

  .medium-push-11 {
    position: relative;
    left: 22.91667%;
    right: auto; }

  .medium-pull-11 {
    position: relative;
    right: 22.91667%;
    left: auto; }

  .medium-push-12 {
    position: relative;
    left: 25%;
    right: auto; }

  .medium-pull-12 {
    position: relative;
    right: 25%;
    left: auto; }

  .medium-push-13 {
    position: relative;
    left: 27.08333%;
    right: auto; }

  .medium-pull-13 {
    position: relative;
    right: 27.08333%;
    left: auto; }

  .medium-push-14 {
    position: relative;
    left: 29.16667%;
    right: auto; }

  .medium-pull-14 {
    position: relative;
    right: 29.16667%;
    left: auto; }

  .medium-push-15 {
    position: relative;
    left: 31.25%;
    right: auto; }

  .medium-pull-15 {
    position: relative;
    right: 31.25%;
    left: auto; }

  .medium-push-16 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .medium-pull-16 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .medium-push-17 {
    position: relative;
    left: 35.41667%;
    right: auto; }

  .medium-pull-17 {
    position: relative;
    right: 35.41667%;
    left: auto; }

  .medium-push-18 {
    position: relative;
    left: 37.5%;
    right: auto; }

  .medium-pull-18 {
    position: relative;
    right: 37.5%;
    left: auto; }

  .medium-push-19 {
    position: relative;
    left: 39.58333%;
    right: auto; }

  .medium-pull-19 {
    position: relative;
    right: 39.58333%;
    left: auto; }

  .medium-push-20 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .medium-pull-20 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .medium-push-21 {
    position: relative;
    left: 43.75%;
    right: auto; }

  .medium-pull-21 {
    position: relative;
    right: 43.75%;
    left: auto; }

  .medium-push-22 {
    position: relative;
    left: 45.83333%;
    right: auto; }

  .medium-pull-22 {
    position: relative;
    right: 45.83333%;
    left: auto; }

  .medium-push-23 {
    position: relative;
    left: 47.91667%;
    right: auto; }

  .medium-pull-23 {
    position: relative;
    right: 47.91667%;
    left: auto; }

  .medium-push-24 {
    position: relative;
    left: 50%;
    right: auto; }

  .medium-pull-24 {
    position: relative;
    right: 50%;
    left: auto; }

  .medium-push-25 {
    position: relative;
    left: 52.08333%;
    right: auto; }

  .medium-pull-25 {
    position: relative;
    right: 52.08333%;
    left: auto; }

  .medium-push-26 {
    position: relative;
    left: 54.16667%;
    right: auto; }

  .medium-pull-26 {
    position: relative;
    right: 54.16667%;
    left: auto; }

  .medium-push-27 {
    position: relative;
    left: 56.25%;
    right: auto; }

  .medium-pull-27 {
    position: relative;
    right: 56.25%;
    left: auto; }

  .medium-push-28 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .medium-pull-28 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .medium-push-29 {
    position: relative;
    left: 60.41667%;
    right: auto; }

  .medium-pull-29 {
    position: relative;
    right: 60.41667%;
    left: auto; }

  .medium-push-30 {
    position: relative;
    left: 62.5%;
    right: auto; }

  .medium-pull-30 {
    position: relative;
    right: 62.5%;
    left: auto; }

  .medium-push-31 {
    position: relative;
    left: 64.58333%;
    right: auto; }

  .medium-pull-31 {
    position: relative;
    right: 64.58333%;
    left: auto; }

  .medium-push-32 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .medium-pull-32 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .medium-push-33 {
    position: relative;
    left: 68.75%;
    right: auto; }

  .medium-pull-33 {
    position: relative;
    right: 68.75%;
    left: auto; }

  .medium-push-34 {
    position: relative;
    left: 70.83333%;
    right: auto; }

  .medium-pull-34 {
    position: relative;
    right: 70.83333%;
    left: auto; }

  .medium-push-35 {
    position: relative;
    left: 72.91667%;
    right: auto; }

  .medium-pull-35 {
    position: relative;
    right: 72.91667%;
    left: auto; }

  .medium-push-36 {
    position: relative;
    left: 75%;
    right: auto; }

  .medium-pull-36 {
    position: relative;
    right: 75%;
    left: auto; }

  .medium-push-37 {
    position: relative;
    left: 77.08333%;
    right: auto; }

  .medium-pull-37 {
    position: relative;
    right: 77.08333%;
    left: auto; }

  .medium-push-38 {
    position: relative;
    left: 79.16667%;
    right: auto; }

  .medium-pull-38 {
    position: relative;
    right: 79.16667%;
    left: auto; }

  .medium-push-39 {
    position: relative;
    left: 81.25%;
    right: auto; }

  .medium-pull-39 {
    position: relative;
    right: 81.25%;
    left: auto; }

  .medium-push-40 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .medium-pull-40 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .medium-push-41 {
    position: relative;
    left: 85.41667%;
    right: auto; }

  .medium-pull-41 {
    position: relative;
    right: 85.41667%;
    left: auto; }

  .medium-push-42 {
    position: relative;
    left: 87.5%;
    right: auto; }

  .medium-pull-42 {
    position: relative;
    right: 87.5%;
    left: auto; }

  .medium-push-43 {
    position: relative;
    left: 89.58333%;
    right: auto; }

  .medium-pull-43 {
    position: relative;
    right: 89.58333%;
    left: auto; }

  .medium-push-44 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .medium-pull-44 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .medium-push-45 {
    position: relative;
    left: 93.75%;
    right: auto; }

  .medium-pull-45 {
    position: relative;
    right: 93.75%;
    left: auto; }

  .medium-push-46 {
    position: relative;
    left: 95.83333%;
    right: auto; }

  .medium-pull-46 {
    position: relative;
    right: 95.83333%;
    left: auto; }

  .medium-push-47 {
    position: relative;
    left: 97.91667%;
    right: auto; }

  .medium-pull-47 {
    position: relative;
    right: 97.91667%;
    left: auto; }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .medium-1 {
    width: 2.08333%; }

  .medium-2 {
    width: 4.16667%; }

  .medium-3 {
    width: 6.25%; }

  .medium-4 {
    width: 8.33333%; }

  .medium-5 {
    width: 10.41667%; }

  .medium-6 {
    width: 12.5%; }

  .medium-7 {
    width: 14.58333%; }

  .medium-8 {
    width: 16.66667%; }

  .medium-9 {
    width: 18.75%; }

  .medium-10 {
    width: 20.83333%; }

  .medium-11 {
    width: 22.91667%; }

  .medium-12 {
    width: 25%; }

  .medium-13 {
    width: 27.08333%; }

  .medium-14 {
    width: 29.16667%; }

  .medium-15 {
    width: 31.25%; }

  .medium-16 {
    width: 33.33333%; }

  .medium-17 {
    width: 35.41667%; }

  .medium-18 {
    width: 37.5%; }

  .medium-19 {
    width: 39.58333%; }

  .medium-20 {
    width: 41.66667%; }

  .medium-21 {
    width: 43.75%; }

  .medium-22 {
    width: 45.83333%; }

  .medium-23 {
    width: 47.91667%; }

  .medium-24 {
    width: 50%; }

  .medium-25 {
    width: 52.08333%; }

  .medium-26 {
    width: 54.16667%; }

  .medium-27 {
    width: 56.25%; }

  .medium-28 {
    width: 58.33333%; }

  .medium-29 {
    width: 60.41667%; }

  .medium-30 {
    width: 62.5%; }

  .medium-31 {
    width: 64.58333%; }

  .medium-32 {
    width: 66.66667%; }

  .medium-33 {
    width: 68.75%; }

  .medium-34 {
    width: 70.83333%; }

  .medium-35 {
    width: 72.91667%; }

  .medium-36 {
    width: 75%; }

  .medium-37 {
    width: 77.08333%; }

  .medium-38 {
    width: 79.16667%; }

  .medium-39 {
    width: 81.25%; }

  .medium-40 {
    width: 83.33333%; }

  .medium-41 {
    width: 85.41667%; }

  .medium-42 {
    width: 87.5%; }

  .medium-43 {
    width: 89.58333%; }

  .medium-44 {
    width: 91.66667%; }

  .medium-45 {
    width: 93.75%; }

  .medium-46 {
    width: 95.83333%; }

  .medium-47 {
    width: 97.91667%; }

  .medium-48 {
    width: 100%; }

  .medium-offset-0 {
    margin-left: 0 !important; }

  .medium-offset-1 {
    margin-left: 2.08333% !important; }

  .medium-offset-2 {
    margin-left: 4.16667% !important; }

  .medium-offset-3 {
    margin-left: 6.25% !important; }

  .medium-offset-4 {
    margin-left: 8.33333% !important; }

  .medium-offset-5 {
    margin-left: 10.41667% !important; }

  .medium-offset-6 {
    margin-left: 12.5% !important; }

  .medium-offset-7 {
    margin-left: 14.58333% !important; }

  .medium-offset-8 {
    margin-left: 16.66667% !important; }

  .medium-offset-9 {
    margin-left: 18.75% !important; }

  .medium-offset-10 {
    margin-left: 20.83333% !important; }

  .medium-offset-11 {
    margin-left: 22.91667% !important; }

  .medium-offset-12 {
    margin-left: 25% !important; }

  .medium-offset-13 {
    margin-left: 27.08333% !important; }

  .medium-offset-14 {
    margin-left: 29.16667% !important; }

  .medium-offset-15 {
    margin-left: 31.25% !important; }

  .medium-offset-16 {
    margin-left: 33.33333% !important; }

  .medium-offset-17 {
    margin-left: 35.41667% !important; }

  .medium-offset-18 {
    margin-left: 37.5% !important; }

  .medium-offset-19 {
    margin-left: 39.58333% !important; }

  .medium-offset-20 {
    margin-left: 41.66667% !important; }

  .medium-offset-21 {
    margin-left: 43.75% !important; }

  .medium-offset-22 {
    margin-left: 45.83333% !important; }

  .medium-offset-23 {
    margin-left: 47.91667% !important; }

  .medium-offset-24 {
    margin-left: 50% !important; }

  .medium-offset-25 {
    margin-left: 52.08333% !important; }

  .medium-offset-26 {
    margin-left: 54.16667% !important; }

  .medium-offset-27 {
    margin-left: 56.25% !important; }

  .medium-offset-28 {
    margin-left: 58.33333% !important; }

  .medium-offset-29 {
    margin-left: 60.41667% !important; }

  .medium-offset-30 {
    margin-left: 62.5% !important; }

  .medium-offset-31 {
    margin-left: 64.58333% !important; }

  .medium-offset-32 {
    margin-left: 66.66667% !important; }

  .medium-offset-33 {
    margin-left: 68.75% !important; }

  .medium-offset-34 {
    margin-left: 70.83333% !important; }

  .medium-offset-35 {
    margin-left: 72.91667% !important; }

  .medium-offset-36 {
    margin-left: 75% !important; }

  .medium-offset-37 {
    margin-left: 77.08333% !important; }

  .medium-offset-38 {
    margin-left: 79.16667% !important; }

  .medium-offset-39 {
    margin-left: 81.25% !important; }

  .medium-offset-40 {
    margin-left: 83.33333% !important; }

  .medium-offset-41 {
    margin-left: 85.41667% !important; }

  .medium-offset-42 {
    margin-left: 87.5% !important; }

  .medium-offset-43 {
    margin-left: 89.58333% !important; }

  .medium-offset-44 {
    margin-left: 91.66667% !important; }

  .medium-offset-45 {
    margin-left: 93.75% !important; }

  .medium-offset-46 {
    margin-left: 95.83333% !important; }

  .medium-offset-47 {
    margin-left: 97.91667% !important; }

  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }

  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }

  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }

  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none; }

  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left; }

  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right; }

  .row.medium-collapse > .column,
  .row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .push-0 {
    position: relative;
    left: 0;
    right: auto; }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto; }

  .push-1 {
    position: relative;
    left: 2.08333%;
    right: auto; }

  .pull-1 {
    position: relative;
    right: 2.08333%;
    left: auto; }

  .push-2 {
    position: relative;
    left: 4.16667%;
    right: auto; }

  .pull-2 {
    position: relative;
    right: 4.16667%;
    left: auto; }

  .push-3 {
    position: relative;
    left: 6.25%;
    right: auto; }

  .pull-3 {
    position: relative;
    right: 6.25%;
    left: auto; }

  .push-4 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .pull-4 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .push-5 {
    position: relative;
    left: 10.41667%;
    right: auto; }

  .pull-5 {
    position: relative;
    right: 10.41667%;
    left: auto; }

  .push-6 {
    position: relative;
    left: 12.5%;
    right: auto; }

  .pull-6 {
    position: relative;
    right: 12.5%;
    left: auto; }

  .push-7 {
    position: relative;
    left: 14.58333%;
    right: auto; }

  .pull-7 {
    position: relative;
    right: 14.58333%;
    left: auto; }

  .push-8 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .pull-8 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .push-9 {
    position: relative;
    left: 18.75%;
    right: auto; }

  .pull-9 {
    position: relative;
    right: 18.75%;
    left: auto; }

  .push-10 {
    position: relative;
    left: 20.83333%;
    right: auto; }

  .pull-10 {
    position: relative;
    right: 20.83333%;
    left: auto; }

  .push-11 {
    position: relative;
    left: 22.91667%;
    right: auto; }

  .pull-11 {
    position: relative;
    right: 22.91667%;
    left: auto; }

  .push-12 {
    position: relative;
    left: 25%;
    right: auto; }

  .pull-12 {
    position: relative;
    right: 25%;
    left: auto; }

  .push-13 {
    position: relative;
    left: 27.08333%;
    right: auto; }

  .pull-13 {
    position: relative;
    right: 27.08333%;
    left: auto; }

  .push-14 {
    position: relative;
    left: 29.16667%;
    right: auto; }

  .pull-14 {
    position: relative;
    right: 29.16667%;
    left: auto; }

  .push-15 {
    position: relative;
    left: 31.25%;
    right: auto; }

  .pull-15 {
    position: relative;
    right: 31.25%;
    left: auto; }

  .push-16 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .pull-16 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .push-17 {
    position: relative;
    left: 35.41667%;
    right: auto; }

  .pull-17 {
    position: relative;
    right: 35.41667%;
    left: auto; }

  .push-18 {
    position: relative;
    left: 37.5%;
    right: auto; }

  .pull-18 {
    position: relative;
    right: 37.5%;
    left: auto; }

  .push-19 {
    position: relative;
    left: 39.58333%;
    right: auto; }

  .pull-19 {
    position: relative;
    right: 39.58333%;
    left: auto; }

  .push-20 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .pull-20 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .push-21 {
    position: relative;
    left: 43.75%;
    right: auto; }

  .pull-21 {
    position: relative;
    right: 43.75%;
    left: auto; }

  .push-22 {
    position: relative;
    left: 45.83333%;
    right: auto; }

  .pull-22 {
    position: relative;
    right: 45.83333%;
    left: auto; }

  .push-23 {
    position: relative;
    left: 47.91667%;
    right: auto; }

  .pull-23 {
    position: relative;
    right: 47.91667%;
    left: auto; }

  .push-24 {
    position: relative;
    left: 50%;
    right: auto; }

  .pull-24 {
    position: relative;
    right: 50%;
    left: auto; }

  .push-25 {
    position: relative;
    left: 52.08333%;
    right: auto; }

  .pull-25 {
    position: relative;
    right: 52.08333%;
    left: auto; }

  .push-26 {
    position: relative;
    left: 54.16667%;
    right: auto; }

  .pull-26 {
    position: relative;
    right: 54.16667%;
    left: auto; }

  .push-27 {
    position: relative;
    left: 56.25%;
    right: auto; }

  .pull-27 {
    position: relative;
    right: 56.25%;
    left: auto; }

  .push-28 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .pull-28 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .push-29 {
    position: relative;
    left: 60.41667%;
    right: auto; }

  .pull-29 {
    position: relative;
    right: 60.41667%;
    left: auto; }

  .push-30 {
    position: relative;
    left: 62.5%;
    right: auto; }

  .pull-30 {
    position: relative;
    right: 62.5%;
    left: auto; }

  .push-31 {
    position: relative;
    left: 64.58333%;
    right: auto; }

  .pull-31 {
    position: relative;
    right: 64.58333%;
    left: auto; }

  .push-32 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .pull-32 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .push-33 {
    position: relative;
    left: 68.75%;
    right: auto; }

  .pull-33 {
    position: relative;
    right: 68.75%;
    left: auto; }

  .push-34 {
    position: relative;
    left: 70.83333%;
    right: auto; }

  .pull-34 {
    position: relative;
    right: 70.83333%;
    left: auto; }

  .push-35 {
    position: relative;
    left: 72.91667%;
    right: auto; }

  .pull-35 {
    position: relative;
    right: 72.91667%;
    left: auto; }

  .push-36 {
    position: relative;
    left: 75%;
    right: auto; }

  .pull-36 {
    position: relative;
    right: 75%;
    left: auto; }

  .push-37 {
    position: relative;
    left: 77.08333%;
    right: auto; }

  .pull-37 {
    position: relative;
    right: 77.08333%;
    left: auto; }

  .push-38 {
    position: relative;
    left: 79.16667%;
    right: auto; }

  .pull-38 {
    position: relative;
    right: 79.16667%;
    left: auto; }

  .push-39 {
    position: relative;
    left: 81.25%;
    right: auto; }

  .pull-39 {
    position: relative;
    right: 81.25%;
    left: auto; }

  .push-40 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .pull-40 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .push-41 {
    position: relative;
    left: 85.41667%;
    right: auto; }

  .pull-41 {
    position: relative;
    right: 85.41667%;
    left: auto; }

  .push-42 {
    position: relative;
    left: 87.5%;
    right: auto; }

  .pull-42 {
    position: relative;
    right: 87.5%;
    left: auto; }

  .push-43 {
    position: relative;
    left: 89.58333%;
    right: auto; }

  .pull-43 {
    position: relative;
    right: 89.58333%;
    left: auto; }

  .push-44 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .pull-44 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .push-45 {
    position: relative;
    left: 93.75%;
    right: auto; }

  .pull-45 {
    position: relative;
    right: 93.75%;
    left: auto; }

  .push-46 {
    position: relative;
    left: 95.83333%;
    right: auto; }

  .pull-46 {
    position: relative;
    right: 95.83333%;
    left: auto; }

  .push-47 {
    position: relative;
    left: 97.91667%;
    right: auto; }

  .pull-47 {
    position: relative;
    right: 97.91667%;
    left: auto; } }
@media only screen and (min-width: $largeMinWidthFoundationBreak) {
  .large-push-0 {
    position: relative;
    left: 0;
    right: auto; }

  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto; }

  .large-push-1 {
    position: relative;
    left: 2.08333%;
    right: auto; }

  .large-pull-1 {
    position: relative;
    right: 2.08333%;
    left: auto; }

  .large-push-2 {
    position: relative;
    left: 4.16667%;
    right: auto; }

  .large-pull-2 {
    position: relative;
    right: 4.16667%;
    left: auto; }

  .large-push-3 {
    position: relative;
    left: 6.25%;
    right: auto; }

  .large-pull-3 {
    position: relative;
    right: 6.25%;
    left: auto; }

  .large-push-4 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .large-pull-4 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .large-push-5 {
    position: relative;
    left: 10.41667%;
    right: auto; }

  .large-pull-5 {
    position: relative;
    right: 10.41667%;
    left: auto; }

  .large-push-6 {
    position: relative;
    left: 12.5%;
    right: auto; }

  .large-pull-6 {
    position: relative;
    right: 12.5%;
    left: auto; }

  .large-push-7 {
    position: relative;
    left: 14.58333%;
    right: auto; }

  .large-pull-7 {
    position: relative;
    right: 14.58333%;
    left: auto; }

  .large-push-8 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .large-pull-8 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .large-push-9 {
    position: relative;
    left: 18.75%;
    right: auto; }

  .large-pull-9 {
    position: relative;
    right: 18.75%;
    left: auto; }

  .large-push-10 {
    position: relative;
    left: 20.83333%;
    right: auto; }

  .large-pull-10 {
    position: relative;
    right: 20.83333%;
    left: auto; }

  .large-push-11 {
    position: relative;
    left: 22.91667%;
    right: auto; }

  .large-pull-11 {
    position: relative;
    right: 22.91667%;
    left: auto; }

  .large-push-12 {
    position: relative;
    left: 25%;
    right: auto; }

  .large-pull-12 {
    position: relative;
    right: 25%;
    left: auto; }

  .large-push-13 {
    position: relative;
    left: 27.08333%;
    right: auto; }

  .large-pull-13 {
    position: relative;
    right: 27.08333%;
    left: auto; }

  .large-push-14 {
    position: relative;
    left: 29.16667%;
    right: auto; }

  .large-pull-14 {
    position: relative;
    right: 29.16667%;
    left: auto; }

  .large-push-15 {
    position: relative;
    left: 31.25%;
    right: auto; }

  .large-pull-15 {
    position: relative;
    right: 31.25%;
    left: auto; }

  .large-push-16 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .large-pull-16 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .large-push-17 {
    position: relative;
    left: 35.41667%;
    right: auto; }

  .large-pull-17 {
    position: relative;
    right: 35.41667%;
    left: auto; }

  .large-push-18 {
    position: relative;
    left: 37.5%;
    right: auto; }

  .large-pull-18 {
    position: relative;
    right: 37.5%;
    left: auto; }

  .large-push-19 {
    position: relative;
    left: 39.58333%;
    right: auto; }

  .large-pull-19 {
    position: relative;
    right: 39.58333%;
    left: auto; }

  .large-push-20 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .large-pull-20 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .large-push-21 {
    position: relative;
    left: 43.75%;
    right: auto; }

  .large-pull-21 {
    position: relative;
    right: 43.75%;
    left: auto; }

  .large-push-22 {
    position: relative;
    left: 45.83333%;
    right: auto; }

  .large-pull-22 {
    position: relative;
    right: 45.83333%;
    left: auto; }

  .large-push-23 {
    position: relative;
    left: 47.91667%;
    right: auto; }

  .large-pull-23 {
    position: relative;
    right: 47.91667%;
    left: auto; }

  .large-push-24 {
    position: relative;
    left: 50%;
    right: auto; }

  .large-pull-24 {
    position: relative;
    right: 50%;
    left: auto; }

  .large-push-25 {
    position: relative;
    left: 52.08333%;
    right: auto; }

  .large-pull-25 {
    position: relative;
    right: 52.08333%;
    left: auto; }

  .large-push-26 {
    position: relative;
    left: 54.16667%;
    right: auto; }

  .large-pull-26 {
    position: relative;
    right: 54.16667%;
    left: auto; }

  .large-push-27 {
    position: relative;
    left: 56.25%;
    right: auto; }

  .large-pull-27 {
    position: relative;
    right: 56.25%;
    left: auto; }

  .large-push-28 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .large-pull-28 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .large-push-29 {
    position: relative;
    left: 60.41667%;
    right: auto; }

  .large-pull-29 {
    position: relative;
    right: 60.41667%;
    left: auto; }

  .large-push-30 {
    position: relative;
    left: 62.5%;
    right: auto; }

  .large-pull-30 {
    position: relative;
    right: 62.5%;
    left: auto; }

  .large-push-31 {
    position: relative;
    left: 64.58333%;
    right: auto; }

  .large-pull-31 {
    position: relative;
    right: 64.58333%;
    left: auto; }

  .large-push-32 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .large-pull-32 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .large-push-33 {
    position: relative;
    left: 68.75%;
    right: auto; }

  .large-pull-33 {
    position: relative;
    right: 68.75%;
    left: auto; }

  .large-push-34 {
    position: relative;
    left: 70.83333%;
    right: auto; }

  .large-pull-34 {
    position: relative;
    right: 70.83333%;
    left: auto; }

  .large-push-35 {
    position: relative;
    left: 72.91667%;
    right: auto; }

  .large-pull-35 {
    position: relative;
    right: 72.91667%;
    left: auto; }

  .large-push-36 {
    position: relative;
    left: 75%;
    right: auto; }

  .large-pull-36 {
    position: relative;
    right: 75%;
    left: auto; }

  .large-push-37 {
    position: relative;
    left: 77.08333%;
    right: auto; }

  .large-pull-37 {
    position: relative;
    right: 77.08333%;
    left: auto; }

  .large-push-38 {
    position: relative;
    left: 79.16667%;
    right: auto; }

  .large-pull-38 {
    position: relative;
    right: 79.16667%;
    left: auto; }

  .large-push-39 {
    position: relative;
    left: 81.25%;
    right: auto; }

  .large-pull-39 {
    position: relative;
    right: 81.25%;
    left: auto; }

  .large-push-40 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .large-pull-40 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .large-push-41 {
    position: relative;
    left: 85.41667%;
    right: auto; }

  .large-pull-41 {
    position: relative;
    right: 85.41667%;
    left: auto; }

  .large-push-42 {
    position: relative;
    left: 87.5%;
    right: auto; }

  .large-pull-42 {
    position: relative;
    right: 87.5%;
    left: auto; }

  .large-push-43 {
    position: relative;
    left: 89.58333%;
    right: auto; }

  .large-pull-43 {
    position: relative;
    right: 89.58333%;
    left: auto; }

  .large-push-44 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .large-pull-44 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .large-push-45 {
    position: relative;
    left: 93.75%;
    right: auto; }

  .large-pull-45 {
    position: relative;
    right: 93.75%;
    left: auto; }

  .large-push-46 {
    position: relative;
    left: 95.83333%;
    right: auto; }

  .large-pull-46 {
    position: relative;
    right: 95.83333%;
    left: auto; }

  .large-push-47 {
    position: relative;
    left: 97.91667%;
    right: auto; }

  .large-pull-47 {
    position: relative;
    right: 97.91667%;
    left: auto; }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .large-1 {
    width: 2.08333%; }

  .large-2 {
    width: 4.16667%; }

  .large-3 {
    width: 6.25%; }

  .large-4 {
    width: 8.33333%; }

  .large-5 {
    width: 10.41667%; }

  .large-6 {
    width: 12.5%; }

  .large-7 {
    width: 14.58333%; }

  .large-8 {
    width: 16.66667%; }

  .large-9 {
    width: 18.75%; }

  .large-10 {
    width: 20.83333%; }

  .large-11 {
    width: 22.91667%; }

  .large-12 {
    width: 25%; }

  .large-13 {
    width: 27.08333%; }

  .large-14 {
    width: 29.16667%; }

  .large-15 {
    width: 31.25%; }

  .large-16 {
    width: 33.33333%; }

  .large-17 {
    width: 35.41667%; }

  .large-18 {
    width: 37.5%; }

  .large-19 {
    width: 39.58333%; }

  .large-20 {
    width: 41.66667%; }

  .large-21 {
    width: 43.75%; }

  .large-22 {
    width: 45.83333%; }

  .large-23 {
    width: 47.91667%; }

  .large-24 {
    width: 50%; }

  .large-25 {
    width: 52.08333%; }

  .large-26 {
    width: 54.16667%; }

  .large-27 {
    width: 56.25%; }

  .large-28 {
    width: 58.33333%; }

  .large-29 {
    width: 60.41667%; }

  .large-30 {
    width: 62.5%; }

  .large-31 {
    width: 64.58333%; }

  .large-32 {
    width: 66.66667%; }

  .large-33 {
    width: 68.75%; }

  .large-34 {
    width: 70.83333%; }

  .large-35 {
    width: 72.91667%; }

  .large-36 {
    width: 75%; }

  .large-37 {
    width: 77.08333%; }

  .large-38 {
    width: 79.16667%; }

  .large-39 {
    width: 81.25%; }

  .large-40 {
    width: 83.33333%; }

  .large-41 {
    width: 85.41667%; }

  .large-42 {
    width: 87.5%; }

  .large-43 {
    width: 89.58333%; }

  .large-44 {
    width: 91.66667%; }

  .large-45 {
    width: 93.75%; }

  .large-46 {
    width: 95.83333%; }

  .large-47 {
    width: 97.91667%; }

  .large-48 {
    width: 100%; }

  .large-offset-0 {
    margin-left: 0 !important; }

  .large-offset-1 {
    margin-left: 2.08333% !important; }

  .large-offset-2 {
    margin-left: 4.16667% !important; }

  .large-offset-3 {
    margin-left: 6.25% !important; }

  .large-offset-4 {
    margin-left: 8.33333% !important; }

  .large-offset-5 {
    margin-left: 10.41667% !important; }

  .large-offset-6 {
    margin-left: 12.5% !important; }

  .large-offset-7 {
    margin-left: 14.58333% !important; }

  .large-offset-8 {
    margin-left: 16.66667% !important; }

  .large-offset-9 {
    margin-left: 18.75% !important; }

  .large-offset-10 {
    margin-left: 20.83333% !important; }

  .large-offset-11 {
    margin-left: 22.91667% !important; }

  .large-offset-12 {
    margin-left: 25% !important; }

  .large-offset-13 {
    margin-left: 27.08333% !important; }

  .large-offset-14 {
    margin-left: 29.16667% !important; }

  .large-offset-15 {
    margin-left: 31.25% !important; }

  .large-offset-16 {
    margin-left: 33.33333% !important; }

  .large-offset-17 {
    margin-left: 35.41667% !important; }

  .large-offset-18 {
    margin-left: 37.5% !important; }

  .large-offset-19 {
    margin-left: 39.58333% !important; }

  .large-offset-20 {
    margin-left: 41.66667% !important; }

  .large-offset-21 {
    margin-left: 43.75% !important; }

  .large-offset-22 {
    margin-left: 45.83333% !important; }

  .large-offset-23 {
    margin-left: 47.91667% !important; }

  .large-offset-24 {
    margin-left: 50% !important; }

  .large-offset-25 {
    margin-left: 52.08333% !important; }

  .large-offset-26 {
    margin-left: 54.16667% !important; }

  .large-offset-27 {
    margin-left: 56.25% !important; }

  .large-offset-28 {
    margin-left: 58.33333% !important; }

  .large-offset-29 {
    margin-left: 60.41667% !important; }

  .large-offset-30 {
    margin-left: 62.5% !important; }

  .large-offset-31 {
    margin-left: 64.58333% !important; }

  .large-offset-32 {
    margin-left: 66.66667% !important; }

  .large-offset-33 {
    margin-left: 68.75% !important; }

  .large-offset-34 {
    margin-left: 70.83333% !important; }

  .large-offset-35 {
    margin-left: 72.91667% !important; }

  .large-offset-36 {
    margin-left: 75% !important; }

  .large-offset-37 {
    margin-left: 77.08333% !important; }

  .large-offset-38 {
    margin-left: 79.16667% !important; }

  .large-offset-39 {
    margin-left: 81.25% !important; }

  .large-offset-40 {
    margin-left: 83.33333% !important; }

  .large-offset-41 {
    margin-left: 85.41667% !important; }

  .large-offset-42 {
    margin-left: 87.5% !important; }

  .large-offset-43 {
    margin-left: 89.58333% !important; }

  .large-offset-44 {
    margin-left: 91.66667% !important; }

  .large-offset-45 {
    margin-left: 93.75% !important; }

  .large-offset-46 {
    margin-left: 95.83333% !important; }

  .large-offset-47 {
    margin-left: 97.91667% !important; }

  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }

  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }

  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }

  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none; }

  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left; }

  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right; }

  .row.large-collapse > .column,
  .row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.large-uncollapse > .column,
  .row.large-uncollapse > .columns {
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .push-0 {
    position: relative;
    left: 0;
    right: auto; }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto; }

  .push-1 {
    position: relative;
    left: 2.08333%;
    right: auto; }

  .pull-1 {
    position: relative;
    right: 2.08333%;
    left: auto; }

  .push-2 {
    position: relative;
    left: 4.16667%;
    right: auto; }

  .pull-2 {
    position: relative;
    right: 4.16667%;
    left: auto; }

  .push-3 {
    position: relative;
    left: 6.25%;
    right: auto; }

  .pull-3 {
    position: relative;
    right: 6.25%;
    left: auto; }

  .push-4 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .pull-4 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .push-5 {
    position: relative;
    left: 10.41667%;
    right: auto; }

  .pull-5 {
    position: relative;
    right: 10.41667%;
    left: auto; }

  .push-6 {
    position: relative;
    left: 12.5%;
    right: auto; }

  .pull-6 {
    position: relative;
    right: 12.5%;
    left: auto; }

  .push-7 {
    position: relative;
    left: 14.58333%;
    right: auto; }

  .pull-7 {
    position: relative;
    right: 14.58333%;
    left: auto; }

  .push-8 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .pull-8 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .push-9 {
    position: relative;
    left: 18.75%;
    right: auto; }

  .pull-9 {
    position: relative;
    right: 18.75%;
    left: auto; }

  .push-10 {
    position: relative;
    left: 20.83333%;
    right: auto; }

  .pull-10 {
    position: relative;
    right: 20.83333%;
    left: auto; }

  .push-11 {
    position: relative;
    left: 22.91667%;
    right: auto; }

  .pull-11 {
    position: relative;
    right: 22.91667%;
    left: auto; }

  .push-12 {
    position: relative;
    left: 25%;
    right: auto; }

  .pull-12 {
    position: relative;
    right: 25%;
    left: auto; }

  .push-13 {
    position: relative;
    left: 27.08333%;
    right: auto; }

  .pull-13 {
    position: relative;
    right: 27.08333%;
    left: auto; }

  .push-14 {
    position: relative;
    left: 29.16667%;
    right: auto; }

  .pull-14 {
    position: relative;
    right: 29.16667%;
    left: auto; }

  .push-15 {
    position: relative;
    left: 31.25%;
    right: auto; }

  .pull-15 {
    position: relative;
    right: 31.25%;
    left: auto; }

  .push-16 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .pull-16 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .push-17 {
    position: relative;
    left: 35.41667%;
    right: auto; }

  .pull-17 {
    position: relative;
    right: 35.41667%;
    left: auto; }

  .push-18 {
    position: relative;
    left: 37.5%;
    right: auto; }

  .pull-18 {
    position: relative;
    right: 37.5%;
    left: auto; }

  .push-19 {
    position: relative;
    left: 39.58333%;
    right: auto; }

  .pull-19 {
    position: relative;
    right: 39.58333%;
    left: auto; }

  .push-20 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .pull-20 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .push-21 {
    position: relative;
    left: 43.75%;
    right: auto; }

  .pull-21 {
    position: relative;
    right: 43.75%;
    left: auto; }

  .push-22 {
    position: relative;
    left: 45.83333%;
    right: auto; }

  .pull-22 {
    position: relative;
    right: 45.83333%;
    left: auto; }

  .push-23 {
    position: relative;
    left: 47.91667%;
    right: auto; }

  .pull-23 {
    position: relative;
    right: 47.91667%;
    left: auto; }

  .push-24 {
    position: relative;
    left: 50%;
    right: auto; }

  .pull-24 {
    position: relative;
    right: 50%;
    left: auto; }

  .push-25 {
    position: relative;
    left: 52.08333%;
    right: auto; }

  .pull-25 {
    position: relative;
    right: 52.08333%;
    left: auto; }

  .push-26 {
    position: relative;
    left: 54.16667%;
    right: auto; }

  .pull-26 {
    position: relative;
    right: 54.16667%;
    left: auto; }

  .push-27 {
    position: relative;
    left: 56.25%;
    right: auto; }

  .pull-27 {
    position: relative;
    right: 56.25%;
    left: auto; }

  .push-28 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .pull-28 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .push-29 {
    position: relative;
    left: 60.41667%;
    right: auto; }

  .pull-29 {
    position: relative;
    right: 60.41667%;
    left: auto; }

  .push-30 {
    position: relative;
    left: 62.5%;
    right: auto; }

  .pull-30 {
    position: relative;
    right: 62.5%;
    left: auto; }

  .push-31 {
    position: relative;
    left: 64.58333%;
    right: auto; }

  .pull-31 {
    position: relative;
    right: 64.58333%;
    left: auto; }

  .push-32 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .pull-32 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .push-33 {
    position: relative;
    left: 68.75%;
    right: auto; }

  .pull-33 {
    position: relative;
    right: 68.75%;
    left: auto; }

  .push-34 {
    position: relative;
    left: 70.83333%;
    right: auto; }

  .pull-34 {
    position: relative;
    right: 70.83333%;
    left: auto; }

  .push-35 {
    position: relative;
    left: 72.91667%;
    right: auto; }

  .pull-35 {
    position: relative;
    right: 72.91667%;
    left: auto; }

  .push-36 {
    position: relative;
    left: 75%;
    right: auto; }

  .pull-36 {
    position: relative;
    right: 75%;
    left: auto; }

  .push-37 {
    position: relative;
    left: 77.08333%;
    right: auto; }

  .pull-37 {
    position: relative;
    right: 77.08333%;
    left: auto; }

  .push-38 {
    position: relative;
    left: 79.16667%;
    right: auto; }

  .pull-38 {
    position: relative;
    right: 79.16667%;
    left: auto; }

  .push-39 {
    position: relative;
    left: 81.25%;
    right: auto; }

  .pull-39 {
    position: relative;
    right: 81.25%;
    left: auto; }

  .push-40 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .pull-40 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .push-41 {
    position: relative;
    left: 85.41667%;
    right: auto; }

  .pull-41 {
    position: relative;
    right: 85.41667%;
    left: auto; }

  .push-42 {
    position: relative;
    left: 87.5%;
    right: auto; }

  .pull-42 {
    position: relative;
    right: 87.5%;
    left: auto; }

  .push-43 {
    position: relative;
    left: 89.58333%;
    right: auto; }

  .pull-43 {
    position: relative;
    right: 89.58333%;
    left: auto; }

  .push-44 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .pull-44 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .push-45 {
    position: relative;
    left: 93.75%;
    right: auto; }

  .pull-45 {
    position: relative;
    right: 93.75%;
    left: auto; }

  .push-46 {
    position: relative;
    left: 95.83333%;
    right: auto; }

  .pull-46 {
    position: relative;
    right: 95.83333%;
    left: auto; }

  .push-47 {
    position: relative;
    left: 97.91667%;
    right: auto; }

  .pull-47 {
    position: relative;
    right: 97.91667%;
    left: auto; } }

/* small displays */
@media only screen {
  .show-for-small-only, .show-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }

  .hide-for-small-only, .hide-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }

  .visible-for-small-only, .visible-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }

  .hidden-for-small-only, .hidden-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }

  table.show-for-small-only, table.show-for-small-up, table.show-for-small, table.show-for-small-down, table.hide-for-medium-only, table.hide-for-medium-up, table.hide-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }

  thead.show-for-small-only, thead.show-for-small-up, thead.show-for-small, thead.show-for-small-down, thead.hide-for-medium-only, thead.hide-for-medium-up, thead.hide-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }

  tbody.show-for-small-only, tbody.show-for-small-up, tbody.show-for-small, tbody.show-for-small-down, tbody.hide-for-medium-only, tbody.hide-for-medium-up, tbody.hide-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }

  tr.show-for-small-only, tr.show-for-small-up, tr.show-for-small, tr.show-for-small-down, tr.hide-for-medium-only, tr.hide-for-medium-up, tr.hide-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }

  th.show-for-small-only, td.show-for-small-only, th.show-for-small-up, td.show-for-small-up, th.show-for-small, td.show-for-small, th.show-for-small-down, td.show-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.hide-for-medium-up, td.hide-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }
/* medium displays */
@media only screen and (min-width: $mediumMinWidthFoundationBreak) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }

  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }

  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }

  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }

  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.show-for-medium-only, table.show-for-medium-up, table.show-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }

  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.show-for-medium-only, thead.show-for-medium-up, thead.show-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }

  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.show-for-medium-only, tbody.show-for-medium-up, tbody.show-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }

  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.show-for-medium-only, tr.show-for-medium-up, tr.show-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }

  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.show-for-medium-only, td.show-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.show-for-medium, td.show-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }
/* large displays */
@media only screen and (min-width: $largeMinWidthFoundationBreak) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important; }

  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important; }

  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }

  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px; }

  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.show-for-large-only, table.show-for-large-up, table.show-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important; }

  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.show-for-large-only, thead.show-for-large-up, thead.show-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important; }

  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.show-for-large-only, tbody.show-for-large-up, tbody.show-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important; }

  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.show-for-large-only, tr.show-for-large-up, tr.show-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row; }

  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.show-for-large-only, td.show-for-large-only, th.show-for-large-up, td.show-for-large-up, th.show-for-large, td.show-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important; } }

/* Orientation targeting */
.show-for-landscape,
.hide-for-portrait {
  display: inherit !important; }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }

/* Specific visibility for tables */
table.hide-for-landscape, table.show-for-portrait {
  display: table !important; }

thead.hide-for-landscape, thead.show-for-portrait {
  display: table-header-group !important; }

tbody.hide-for-landscape, tbody.show-for-portrait {
  display: table-row-group !important; }

tr.hide-for-landscape, tr.show-for-portrait {
  display: table-row !important; }

td.hide-for-landscape, td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell !important; }

@media only screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: inherit !important; }

  .hide-for-landscape,
  .show-for-portrait {
    display: none !important; }

  /* Specific visibility for tables */
  table.show-for-landscape, table.hide-for-portrait {
    display: table !important; }

  thead.show-for-landscape, thead.hide-for-portrait {
    display: table-header-group !important; }

  tbody.show-for-landscape, tbody.hide-for-portrait {
    display: table-row-group !important; }

  tr.show-for-landscape, tr.hide-for-portrait {
    display: table-row !important; }

  td.show-for-landscape, td.hide-for-portrait,
  th.show-for-landscape,
  th.hide-for-portrait {
    display: table-cell !important; } }
@media only screen and (orientation: portrait) {
  .show-for-portrait,
  .hide-for-landscape {
    display: inherit !important; }

  .hide-for-portrait,
  .show-for-landscape {
    display: none !important; }

  /* Specific visibility for tables */
  table.show-for-portrait, table.hide-for-landscape {
    display: table !important; }

  thead.show-for-portrait, thead.hide-for-landscape {
    display: table-header-group !important; }

  tbody.show-for-portrait, tbody.hide-for-landscape {
    display: table-row-group !important; }

  tr.show-for-portrait, tr.hide-for-landscape {
    display: table-row !important; }

  td.show-for-portrait, td.hide-for-landscape,
  th.show-for-portrait,
  th.hide-for-landscape {
    display: table-cell !important; } }
/* Touch-enabled device targeting */
.show-for-touch {
  display: none !important; }

.hide-for-touch {
  display: inherit !important; }

.touch .show-for-touch {
  display: inherit !important; }

.touch .hide-for-touch {
  display: none !important; }

/* Specific visibility for tables */
table.hide-for-touch {
  display: table !important; }

.touch table.show-for-touch {
  display: table !important; }

thead.hide-for-touch {
  display: table-header-group !important; }

.touch thead.show-for-touch {
  display: table-header-group !important; }

tbody.hide-for-touch {
  display: table-row-group !important; }

.touch tbody.show-for-touch {
  display: table-row-group !important; }

tr.hide-for-touch {
  display: table-row !important; }

.touch tr.show-for-touch {
  display: table-row !important; }

td.hide-for-touch {
  display: table-cell !important; }

.touch td.show-for-touch {
  display: table-cell !important; }

th.hide-for-touch {
  display: table-cell !important; }

.touch th.show-for-touch {
  display: table-cell !important; }

/* Screen reader-specific classes */
.show-for-sr {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px; }

.show-on-focus {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px; }
  .show-on-focus:focus, .show-on-focus:active {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto; }

/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
.print-only {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    /* Black prints faster: h5bp.com/s */
    text-shadow: none !important; }

  .show-for-print {
    display: block; }

  .hide-for-print {
    display: none; }

  table.show-for-print {
    display: table !important; }

  thead.show-for-print {
    display: table-header-group !important; }

  tbody.show-for-print {
    display: table-row-group !important; }

  tr.show-for-print {
    display: table-row !important; }

  td.show-for-print {
    display: table-cell !important; }

  th.show-for-print {
    display: table-cell !important; }

  a,
  a:visited {
    text-decoration: underline; }

  a[href]:after {
    content: " (" attr(href) ")"; }

  abbr[title]:after {
    content: " (" attr(title) ")"; }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }

  pre {
    border: 1px solid #999999;
    page-break-inside: avoid; }

  thead {
    display: table-header-group;
    /* h5bp.com/t */ }

  tr,
  img {
    page-break-inside: avoid; }

  img {
    max-width: 100% !important; }

  @page {
    margin: .5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }

  h2,
  h3 {
    page-break-after: avoid; }

  .hide-on-print {
    display: none !important; }

  .print-only {
    display: block !important; }

  .hide-for-print {
    display: none !important; }

  .show-for-print {
    display: inherit !important; } }
/* Print visibility */
@media print {
  .show-for-print {
    display: block; }

  .hide-for-print {
    display: none; }

  table.show-for-print {
    display: table !important; }

  thead.show-for-print {
    display: table-header-group !important; }

  tbody.show-for-print {
    display: table-row-group !important; }

  tr.show-for-print {
    display: table-row !important; }

  td.show-for-print {
    display: table-cell !important; }

  th.show-for-print {
    display: table-cell !important; } }
@media not print {
  .show-for-print {
    display: none !important; } }
