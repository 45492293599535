.featured-products-widget {
    text-align: center;
    margin-bottom: 50px;
    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        margin-bottom: 30px;
    }
    .row {
        margin: 0 -0.625em;
    }
    .featured-products-widget-header {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $minsk;
        text-align: left;
        h3 {
            line-height: 1.1;
            margin: 10px 0px 25px;
            color: $minsk;
        }
    }
    .featured-products-container {
        width: 100%;
        margin: 0 auto;
        max-width: 100%;
        text-align: left;
        .view-all-products-button-container,
        .view-more-case-studies-button-container {
            text-align: center;
            display: inline-block;
    		width: 100%;
            .view-more-case-studies-button,
            .view-all-products-button {
                display: inline-block;
                padding: 10px 25px;
                margin-bottom: 0;
                @include fontSize(1.5);
                font-weight: bold;
                line-height: 1.6;
                text-align: center;
                vertical-align: middle;
                color: $white;
                background-color: $torchRed;
                border-radius: 25px;
                margin-top: 62px;

                &:after {
                    content: '\00bb';
                    @include fontSize(1.5);
                    font-weight: bold;
                    line-height: 1.6;
                }
            }
        }

        .view-more-case-studies-button {
       	    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
                margin-top: 35px!important;
            }        	
        }
    }
    .product-price-selector {
        margin-bottom: 7px;
    }
    .featured-product {
        min-height: 350px; 
        display: inline-block;
        padding: 0 0.625em;
        text-align: left;
        @media screen and (max-width: $mediumMinWidthFoundationBreak) {
            margin-bottom: 30px;
            width: 50%;
            min-height: 360px;
        }
        @media screen and (max-width: $smallMinWidthFoundationBreak) {
            width: 100%;
            min-height: 0;
        }
    }
    .featured-product-image-link {
        display: inline-block;
        border: 3px solid $lightAthensGray;
        padding: 10px;
        height: 100%;
        width: 100%;
        max-width: 220px;
        .featured-product-image-wrapper {
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 87.6%;
            position: relative;
            .featured-product-image {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
    .featured-product-name {
        min-height: 57px;
        line-height: 19px;
        margin-top: 20px;
        margin-bottom: 4px;
        color: $mildGray;
        display: block;
        text-decoration: none;
        @include fontSize(1.5);
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }
    .featured-product-price {
        display: block;
        color: $black;
        font-weight: bold;
        @include fontSize(1.5);
        margin-bottom: 10px;
    }
    .lbl-edit-quantity {
        display: inline-block;
        margin-bottom: 5px;
        font-weight: bold;
        width: 30px;
        margin-top: 7px;
        @include fontSize(1.5);
    }
    .txt-edit-quantity {
        margin: 3px 7px;
        line-height: 1.42857143;
        max-width: 100%;
        width: 50px;
        display: inline-block;
        height: 34px;
        @include inputStyling();
    }
    .btn-quantity-submit {
        font-family: inherit;
        @include fontSize(1.45);
        margin: 0px;
        line-height: 1.428571429;
        cursor: pointer;
        padding: 10px 20px;
        font-weight: bold;
        vertical-align: middle;
        border: 0px none;
        border-radius: 15px;
        white-space: nowrap;
        -webkit-user-select: none;
        color: $white;
        position: relative;
        background-color: $mountainMeadow;
    }
    //  {
    // 	display: inline-block;
    //        padding: 10px 25px;
    //        margin-bottom: 0;
    //        @include fontSize(1.5);
    //        font-weight: bold;
    //        line-height: 1.6;
    //        text-align: center;
    //        vertical-align: middle;
    //        color: $white;
    //        background-color: $torchRed;
    //        border-radius: 25px;
    //        margin-top: 62px;
    //        @media screen and (max-width: $mediumMinWidthFoundationBreak) {
    //            margin-top: 0px;
    //        }
    //        &:after {
    //            content: '\00bb';
    //            @include fontSize(1.5);
    //            font-weight: bold;
    //            line-height: 1.6;
    //        }
    // }
}
        .view-all-products-button-container,
        .view-more-case-studies-button-container {
            text-align: center;
            display: inline-block;
            width: 100%;
            .view-more-case-studies-button,
            .view-all-products-button {
                display: inline-block;
                padding: 10px 25px;
                margin-bottom: 0;
                @include fontSize(1.5);
                font-weight: bold;
                line-height: 1.6;
                text-align: center;
                vertical-align: middle;
                color: $white;
                background-color: $torchRed;
                border-radius: 25px;
                margin-top: 62px;

                &:after {
                    content: '\00bb';
                    @include fontSize(1.5);
                    font-weight: bold;
                    line-height: 1.6;
                }
            }
        }