#MAIMASSearchResultsSection {
  .panel-links {
    .link {
      padding-bottom: 10px;
    }

    .select-institution {
      display: block;
      padding: 15px;
      background-color: #19B5A5;
      color: #fff;
      position: relative;
      padding-right: 28px;

      &:after {
        @include icon($rs-icons-arrow-right, 18px, #fff);
        display: block;
        top: 50%;
        margin-top: -9px;
        right: 7px;
        position: absolute;
      }
    }

    .text {
      display: block;
    }
  }
}

.register.rs-account-selection {
  .subjectTitleSmaller {
    padding-left: 10px;
  }
}

.viewCart.rs-account-selection {

  min-height: 0;

  .button-row {
    margin: auto;
    max-width: 940px;
    padding-top: 72px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
