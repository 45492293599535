section.general-page {
	table {
		font-size: 15px;
		tr {

		}

		td {

		}

		th {
			background-color: $minsk;
			color: #fff;
			padding: 10px;
			border: 1px solid $minsk;
		}

		thead {

		}

		tbody {

		}

	}
}