﻿.rs-blog-rss-feed-button a {
    margin-top: 35px;
    display: inline-block;
    background-color: #ff0048;
    border-radius: 22px;
    height: 44px;
    width: 100%;
    padding-top: 11px;
    color: #fff;
    text-align: center;
    font-weight: bold;

    &:after {
        display: inline-block;
        padding-left: 3px;
        content: '\00BB';
        color: #fff;
        font-size: 15px;
    }

    > span {
        display: inline-block;
        font-size: 19px;
        @media screen and (max-width: 764px) and (min-width: $mediumMinWidthFoundationBreak) {
            font-size: 14px;
            padding-top: 4px;
        }
    }

    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

.blog-page {
    margin: 0!important;
    
    .blog-summary,
    .blog-post {
        // margin-bottom: 30px;
        ul {
            margin-top: 0;
            display: block;
            list-style-type: disc;
            -webkit-margin-before: 1em;
            -webkit-margin-after: 1em;
            -webkit-margin-start: 0px;
            -webkit-margin-end: 0px;
            -webkit-padding-start: 40px;
            color: #333;
            margin-bottom: 24px;
            font-size: 1.5rem;
        }
    }
    h2 {
        text-align: left;

        a {
            color: $diffGrey;
            font-weight: normal;
            line-height: 32px;
            margin: 20px 0 10px;
            @include fontSize(2.8);
        }
    }
    .blog-post-date {
        color: $java;
        width: 100%;
        float: left;
        @include fontSize(1.3);
        margin-bottom: 15px;
    }

    .blog-page-content {
        max-width: 800px;
        font-size: 15px;
        overflow: hidden;
        padding-right: 16px;
        @media screen and (max-width: $mediumMinWidthFoundationBreak) {
            padding: 0;
        }

        @media screen and (max-width: 930px) {
            max-width: 100%;
            padding-right: 0;
        }
    }

    p {
        line-height: 23px;
        color: $mineShaft;
        margin-bottom: 24px;
        a {
            box-sizing: border-box;
            color: $torchRed;
            text-decoration: none;
            font-weight: bold;
        }
    }

    .blog-post-text {
        width: 70%;
        @media screen and (max-width: 450px) {
            width: 100%;
        }
    }

    .blog-summary-image {
        width: 30%;
        
        @media screen and (max-width: 450px) {
            width: 100%;
            margin: 0 auto;
        }

        img {
            margin: 0 auto;
            display: block;
            width: 100%;
            margin-bottom: 10px;
            @media screen and (max-width: 450px) {
                max-height: 130px;
                width: auto;
            }
        }
    }

    .heading-area {
        text-align: center;
    }

    &.blog-page-legacy {
        .container {
            max-width: 960px;
        }

        .blog-filter-control {
            display: block;
        }

        .box-panel {
            a.bullet-links
            {
                width: 100%;
                display: block;
                padding: 4px;
                padding-right: 10px;
                padding-left: 10px;
                color: $brand-primary;
                margin-bottom: 2px;
            }
        }

        .heading-area {
            text-align: left;
        }
    }
}

.blog-search-container {
    max-width: 800px;
    position: relative;

    @media screen and (max-width: 930px) {
        width: 100%;
    }

    .search-submit {
        width: 20px;
        position: absolute;
        top: 61px;
        right: 21px;
        border: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('/images/Rising-Stars/core/svg/svg--search-white.svg');
    }
}

.blog-search {
    width: 100%;
    max-width: 800px;
    padding: 15px 50px 15px 20px;
    background-color: $minsk;
    border: 0;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    outline: 0;
    margin-top: 45px;
    margin-bottom: 45px;

    &::-webkit-input-placeholder { /* Edge */
        color: #fff;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #fff;
    }

    &::placeholder {
        color: #fff;
    }

    @media screen and (max-width: 930px) {
        max-width: 100%;
    }
}

.blog-columns {
    display: flex;
    clear: both;

    @media screen and (max-width: 930px) {
        flex-direction: column;
    }
}

.blog-list {
    @media screen and (max-width: 930px) {
        width: 100%;
        order: 2;
    }
}

.blog-filters {
    @media screen and (max-width: 930px) {
        width: 100%;
        order: 1;
        background-color: #EFF5F3;
        padding: 25px 35px 42px;
        max-height: 30px;
        overflow: hidden;
        transition: max-height .4s ease-in-out;
        
        &.is-open {
            max-height: 1500px;

            .blog-filters-mobile {
                border-bottom: 1px solid $athensGray;
            }
        }
    }

    .blog-filters-mobile {
        width: 100%;
        height: 66px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-top: 5px;
        margin-top: -25px;
        font-size: 18px;
        color: $minsk;
        text-align: center;
        margin-bottom: 23px;

        svg {
            margin-left: 10px;
        }

        @media screen and (min-width: 930px) {
            display: none;
        }
    }

    .blog-filters__section {
        margin-bottom: 45px;

        @media screen and (max-width: 930px) {
            margin-bottom: 0;

            &:last-of-type {
                display: none;
            }
        }
    }

    .blog-filters__section-title {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        border-bottom: 1px solid $minsk;
        padding-bottom: 8px;
        margin-bottom: 12px;

        svg {
            display: none;
            transition: transform .4s ease-in-out;
        }

        &.is-open {
            svg {
                transform: rotate(180deg);
            }
        }

        @media screen and (max-width: 930px) {
            h3 {
                font-size: 15px;
            }

            svg {
                display: block;
            }
        }
    }

    .blog-filters__options {
        @media screen and (max-width: 930px) {
            max-height: 0;
            overflow: hidden;
            transition: max-height .4s ease-in-out;

            &.is-open {
                max-height: 1500px;
            }
        }

        .keyword-search {
            border: 1px solid #707070;
            width:100%;
            padding: 10px 20px;
            border-radius: 10px;
            font-size: 16px;
            color: $minsk;
        }

        a {
            display: block;
            margin-top: 20px;
            color: $diffGrey;

            h2 {
                color: $diffGrey;
            }
        }

        &.blog-filters__options--text-links {
            a {
                font-size: 16px;
                text-decoration: underline;
                color: $minsk;

                @media screen and (max-width: 930px) {
                    font-size: 13px;
                }
            }
        }

        &.blog-filters__options--popular {
            @media screen and (max-width: 930px) {
                display: none;
            }

            a {
                display: flex;

                .popular-image {
                    max-width: 40%;
                    margin-right: 10px;
                }

                .popular-content {
                    h2 {
                        margin-top: 0;
                        line-height: 30px;
                    }

                    .blog-post-date{
                        line-height: 20px;
                    }
                }
            }
        }


        .radio, .checkbox {
            width: 100%;
            padding-top: 5px;
            padding-right: 18px;
            padding-bottom: 5px;
            padding-left: 18px;
            margin-bottom: 20px;
            border-radius: 5px;

            outline: 0;
            transition: border-color 0.3s ease-out, background-color 0.3s ease-out;
            resize: vertical;
            font-size: 16px;
            line-height: 24px;
          
            @media screen and (max-width: 930px) {
              font-size: 13px;
            }
          }
    }
}

.blog-filter-control {
    display: none;
    margin-top: 20px;

    background-color: #ff6934;
    color: #fff;
    padding: 10px;
    padding-bottom: 20px;

    letter-spacing: -0.31em;
    > *{
        letter-spacing: normal;
    }
    .filter-checkboxes {
        padding-right: 5px;
        display: block;
        overflow: hidden;
        .checkbox-cont {
            display: block;
            position: relative;
            padding-bottom: 3px;
            vertical-align: top;

            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            .subject-checkbox-label {
                display: block;
                padding-left: 23px;
                padding-bottom: 4px;
                font-size: 15px;
            }

            input[type=checkbox] {
                padding-top: 1px;
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
        
        .checkbox-list-title {
            font-size: 21px;
            padding-top: 10px;
            padding-bottom: 16px;
        }

        .checkbox-list {
            -moz-column-count: 3;
            -moz-column-gap: 2px;
            -ms-column-count: 3;
            -ms-column-gap: 3px;
            -webkit-column-count: 3;
            -webkit-column-gap: 2px;
            column-count: 3;
            column-gap: 2px;

            @media screen and (max-width: 886px) {
                -moz-column-count: 2;
                -ms-column-count: 2;
                -webkit-column-count: 2;
                column-count: 2;
            }
            @media screen and (max-width: 360px) {
                -moz-column-count: 1;
                -ms-column-count: 1;
                -webkit-column-count: 1;
                column-count: 1;
            }
        }
        
    }


    @media screen and (max-width: 550px) {
        .filter-checkboxes {
            display: block;
            width: auto;
        }

       
    }

    

}

.addthis_sharing_toolbox {
    margin-bottom: 30px;
}

/* RSAccessment blog additions - classes migrated from old blog */

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.blog-pin-text {
  display: inline-block;
  padding: 1em;
  background-color: $java;
  color: $white;
}

.blog-summary {
  margin-top: 2em;
  margin-bottom: 1em;
}

.blog-page h2 {
  text-align: left;
  border-top: 2px solid #25B9AA;
  padding-top: 1em;
  margin-top: 0px;
}
