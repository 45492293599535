.he-contact-details
{
	display:none;
}
.gp-contact-details
{
	display:none;
}
.rs-contact-details
{
	display:block;
}


.sg-contact-details {
    display: none;
}   

.content-image-left {
	float: left;
	margin-right: 20px;
	margin-bottom: 20px;
}

.content-image-right {
	float: right;
	margin-left: 20px;
	margin-bottom: 20px;
}

.content-image-center {
	display: block;
    margin-left: auto;
    margin-right: auto!important;
    float: none!important;
}

.heading-area {
    margin-bottom: 35px;
    margin-top: 35px;
}

.bottom-border {
	border: 1px solid $minsk;
}

.remove-left-padding {
	padding-left: 0!important;
}

.bold-text-700 {
	font-weight: 700;
}

.box-panel {
	margin: 35px 0px 20px 0px;
	padding: 0px;
	padding-top: 10px;
	padding-bottom: 10px;

	@media screen and (max-width: $mediumMinWidthFoundationBreak) {
		margin: 0px 0px 20px 0px;
	}

	h3 {
		margin-top: 10px;
		margin-bottom: 15px;
		padding-bottom: 8px;
		border-bottom: 1px solid $minsk;
		padding-left: 10px;
	}
	
	a.bullet-links {
		width: 100%;
		display: block;
		padding: 4px;
		padding-right: 10px;
		padding-left: 10px;

		color: $brand-primary;
		margin-bottom: 2px;

		&:before {
			content: '\2022';
			padding-right: 8px;
			padding-left: 2px;
		}

		&:hover {
			background-color: $alto;
			text-decoration: none;
		}
	}
	

	a.link-under-list {
		display: block;
		margin: 15px 0px;
	}
}

.grey-bg {
	background-color: $athensGray;
}

.section-gap {
	margin: 60px 0px 30px 0px;
}

.remove {
	display: none;
}
