﻿.login {
  padding-top: 40px;

  @media screen and (min-width: $mediumMinWidthFoundationBreak) {
    padding: 74px 10px 127px;
  }

  .outer-container {
    @extend .row;
  }

  .outer-container {
    max-width: 100%;
    padding-top: 25px;

    @media screen and (min-width: $mediumMinWidthFoundationBreak) {
      padding-top: 57px;
    }
  }

  .content {
    padding: 0;
  }

  .subjectTitleSmaller {
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .subjectSubTitleSmaller {
    text-align: left;
    margin: 0;
  }

  h3 {
    @include fontSize(2.1);
  }

  .label {
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    cursor: default;
    display: inline-block;
    font-family: Oxygen, sans-serif;
    font-size: 15px;
    font-weight: bold;
    line-height: 21.4285717010498px;
    margin: 15px 0;
  }

  .hidden {
    display: none;
  }

  input {
    @include inputStyling();
    width: 100%;
  }

  .container {
    padding: 25px;
    max-width: 100%;
    border: $gallery 3px solid;
    min-height: 348px;

    @media screen and (min-width: $largeMinWidthFoundationBreak) {
      padding: 25px 75px;
    }
  }

  h3 {
    text-align: center;
    margin: 0;
  }

  .left {
    @extend .medium-24;
    @extend .columns;
  }

  .right {
    @extend .medium-24;
    @extend .columns;
    margin-bottom: 25px;
  }

  .left,
  .right {
    @media screen and (min-width: $mediumMinWidthFoundationBreak) {
      padding: 0 10px;
    }
  }

  .left {
    padding-bottom: 20px;

    @media screen and (min-width: $mediumMinWidthFoundationBreak) {
      padding-left: 0;
      padding-bottom: 0;
    }
  }

  .right {
    @media screen and (min-width: $mediumMinWidthFoundationBreak) {
      padding-right: 0;
    }

    .container {
      padding: 25px 0 0;
    }

    h3,
    p {
      @media screen and (min-width: $largeMinWidthFoundationBreak) {
        padding-right: 33px;
      }
    }
  }

  .working {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.8;
    z-index: 100;
    &:before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      top: 50%;
      left: 50%;
      margin-left: -16px;
      margin-top: -16px;
      background-image: url(/images/general/loading.gif);
      position: absolute;
    }

    &.hidden {
      display: none;
    }
  }

  .extraDetailsPanel {
    display: none;
  }

  .forgotton-link {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
  }

  .button-container {
    a {
      color: #fff;
      text-decoration: none;
      @include fontSize(1.456);
      background-color: #006d77;
      border-radius: 8px;
      padding: 10px 20px;
      margin: 0;
    }

    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    @include fontSize(1.456);
    font-weight: bold;
    letter-spacing: normal;
    line-height: 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
  }

  .button-panel {
    margin-top: 24px;
    @include justifyLayout();

    .button-container {
      margin-bottom: 0;
      margin-top: 10px;

      @media screen and (min-width: $mediumMinWidthFoundationBreak) {
        margin-top: 0;
      }
    }
  }

  .registration-button-panel {
    position: relative;
    margin-bottom: 10px;
    @include justifyLayout();

    .button-container {
      position: relative;
      width: 221px;
      height: 43px;
      margin-right: 15px;

      a {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .registration-content {
    padding: 0 25px;

    p {
      margin: 15px 0 0;
    }

    @media screen and (min-width: $largeMinWidthFoundationBreak) {
      padding-left: 65px;
      padding-right: 32px;
    }

    &.register-school {
      margin-bottom: 28px;
    }
  }

  .accordion-content {
    position: relative;
    display: none;
    background: $gallery;
    padding: 20px;
  }

  .accordion-trigger-wrapper {
    display: inline-block;
    margin-top: 10px;
  }

  .accordion-trigger {
    @include fontSize(1.2);
    color: $mountainMeadow;
    line-height: 19px;
    position: relative;
  }

  .active {
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid $gallery;
      position: absolute;
      bottom: -32px;
      right: 0;
      left: 0;
      margin: 0 auto;

      @media screen and (min-width: $largeMinWidthFoundationBreak) and (max-width: $largeMinWidthFoundationBreak + 2.188em),
        screen and (min-width: $mediumMinWidthFoundationBreak) and (max-width: $mediumMinWidthFoundationBreak + 15em),
        screen and (max-width: $smallMinWidthFoundationBreak + 7.313em) {
        bottom: -18px;
      }
    }
  }

  .registration-accordion-content {
    @include fontSize(1.2);
    line-height: 19px;
    color: doveGray;
  }

  .send-password {
    margin: 0;
    margin-top: 24px;
    margin-bottom: 10px;
  }

  .subtext {
    font-size: 10px;
    float: left;
  }
}
