.viewCart {
	min-height: 457px;


	.inner {
		@media screen and (max-width: $smallMinWidthFoundationBreak) {
			display: none;
		}	
	}

	#processingContainer {
		position: relative;
		padding-right: 300px;

		@media screen and (max-width: $largeMinWidthFoundationBreak) {
        	padding-right: 0px;
        }

		.address-information {
			margin-left: 0px;
			margin-top: 0px;
			padding-top: 0px;
			color: $midGray;
		}

		.address-information.order-processing {
			font-size: 22px;
			color: $silver-dot;
			margin-bottom: 7px;
		}
	}


	.rs-order-confirmation-error-email {
		margin: 10px;
	}



	.button-row.order {
		position: relative;
		padding-top: 20px;
	}

	.button.right.button-checkout.order-confirmation {
		position: absolute;
		right: 0;
		bottom: -8px;
		width: 200px;
		height: 44px;

		@media screen and (max-width: $largeMinWidthFoundationBreak) {
        	position: static;
        	margin-top: 10px;
        	margin-bottom: 10px;
        	max-width: 100%;
        }
	}

	.order-confirmation-button {
		margin-top: 20px;
		
		em {
			font-style: normal;
		}
		
		a {
			font-weight: bold;
		}

	}

	.order-reference-confirmation {
		font-size: 3rem;
		color: #19B5A5;
		font-weight: bold;
	}

	.cart-col-3 {

		.qty {
			@media screen and (max-width: $largeMinWidthFoundationBreak) {
				padding-right: 53px; 
			}	
		}
	}

	.technical-problems-order-confirmation {
		margin-bottom: 10px;
	}
	#SecureTradingFailure{
        display: none;
	}

	.successContainer {
       	h2 {
			padding-top: 10px;
			padding-bottom: 19px;
			font-size: 2.15rem;
			color: $silver-dot;
		}
		.order-reference-confirmation {
			color: #19B5A5;
			font-size: 3rem; 
		}

	}
    	.SecureTradingFailure {
		h2 {
			padding-top: 10px;
			padding-bottom: 19px;
			font-size: 2.15rem;
			color: $silver-dot;
		}
		.order-reference-confirmation {
			color: #19B5A5;
			font-size: 3rem; 
		}

	}

	.order-confirmation-grey-text {
		color: $brand-primary;
	}


}
	.order-processing-stars {
		width: 295px;
		height: 56px;
		background: url("/risingstars/images/star-animation.gif") no-repeat; 
		background-size: 100% auto;
		position: absolute;
		margin-right: 5px;
		right: 0;
		top: 0;

		@media screen and (max-width: $mediumMinWidthFoundationBreak) {
        	top: 120%;
        	margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			max-width: 100%;
        }
	}

