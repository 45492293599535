footer {
  h5 {
    color: $white;
  }

  ul {
    list-style: none !important;
    list-style-type: none !important;
    padding-left: 0px;
  }

  color: $white;

  .copyright-mark {
    @include fontSize(1.2);
    line-height: 23px;
    color: #fff;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    display: block;
    list-style: decimal;
    color: $white;
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;

    li {
      @include fontSize(1.5);
      line-height: 1.42857143;
    }
  }

  h6 {
    color: $white;
    line-height: 23px;
    margin: 0 0 20px;
    font-weight: inherit;
  }
}

.primary-footer {
  padding: 50px 0;
  background: #2e2e2e;
}

#primary-footer-nav {
  li {
    font-size: 15px;
    line-height: 23px;
    margin: 0 0 20px;

    a {
      font-weight: bold;
    }
  }
}

.secondary-footer {
  padding: 20px 0;
  background: $black;
}

#secondary-footer-nav {
  li {
    display: inline-block;
    margin-top: 5px;

    a {
      display: block;
      @include fontSize(1.2);
      line-height: 12px;
      color: #fff;
      text-decoration: none;
      padding: 0 5px;
      border-right: 1px solid $white;

      &:hover {
        text-decoration: underline;
      }
    }

    @media only screen and (min-width: $mediumMinWidthFoundationBreak) {
      &:first-child {
        a {
          padding-left: 0px;
        }
      }
    }

    &:last-child {
      a {
        border-right: none;
      }
    }
  }
}

.footer-title {
  line-height: 1.4;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.footer-block {
  vertical-align: top;
  padding: 0 10px;

  @media only screen and (min-width: $mediumMinWidthFoundationBreak) {
    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }
  }
}

.footer-help {
  p {
    color: $white;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-credits {
  display: block;
  margin-top: 5px;
  vertical-align: top;
  @include fontSize(1.2);
  line-height: 23px;

  @media only screen and (min-width: $mediumMinWidthFoundationBreak) {
    text-align: right;
  }

  a {
    color: $white;
    text-decoration: none;
    @include fontSize(1.2);
    line-height: 23px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-reasons {
  ol {
    padding-left: 0px;
    list-style-position: inside;
  }
}
