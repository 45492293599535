.rs-card-list {
  display: flex;
  justify-content: center;
  padding: 150px 30px;
  overflow: hidden;
  position: relative;
  background-color: $brand-primary;

  svg {
    &#rs-stars {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    max-width: 1116px;
    width: 100%;
    position: relative;
  }

  &__content-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  &__title {
    font-size: 32px;
    line-height: 30px;
    font-weight: 600;
    padding-bottom: 15px;
    text-align: left;
    font-family: 'Oxygen', sans-serif;
    color: $brand-white;
  }

  a {
    &.rs-card-list__button {
      display: inline-flex;
      align-items: center;
      font-weight: 700;
      border-radius: 8px;
      padding: 10px 20px;
      line-height: 1;
      font-size: 14px;
      cursor: pointer;
      font-family: 'Oxygen', sans-serif;
      background-color: $brand-yellow;
      color: $brand-primary;

      svg {
        height: 25px;
        margin-left: 10px;
        fill: $brand-primary;
      }

      &.eduu-desktop-only {
        @media (max-width: $screen-mobile) {
          display: none;
        }
      }

      &.eduu-mobile-only {
        @media (min-width: $screen-mobile) {
          display: none;
        }
      }
    }
  }

  &__cards {
    display: flex;
    justify-content: flex-start;

    @media (max-width: $screen-mobile) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__article-card,
  &__event-card {
    flex: 0 1 24%;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.2);
    margin-right: 10px;

    @media (max-width: $screen-mobile) {
      flex: 0 1 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    &--image {
      img {
        width: 100%;

        @media (max-width: $screen-mobile) {
          max-width: 260px;
        }
      }
    }

    &--content {
      background-color: $brand-white;
      padding: 20px;
      text-align: left;
    }

    &--date {
      font-size: 12px;
      line-height: 22px;
      color: #454545;
      padding-bottom: 5px;
      font-family: 'Oxygen', sans-serif;
    }

    &--where {
      font-size: 12px;
      line-height: 22px;
      color: #454545;
      padding-bottom: 5px;
      font-family: 'Oxygen', sans-serif;
    }

    &--title {
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      padding-bottom: 5px;
      font-family: 'Oxygen', sans-serif;
      color: $brand-primary;
    }
    a {
      &.rs-card-list__article-card--link,
      &.rs-card-list__event-card--link {
        color: $brand-black;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
