﻿.carousel-wrapper {
	width: 100%;
	position: relative;

	li {
		display: inline-block;
	}
}


.home-page-banner {
	position: relative;
	background-size: 100% 100%;
}

.banner-carousel {
	// .home-page-banner {
	// 	height: 0;
	// 	padding-bottom: 29.9%;
	// }

	.hidden-banner-image {
		visibility: hidden;
		display: none;


		@media screen and (min-width: $mediumMinWidthFoundationBreak) {
            display: block;
            width: 100%;
            height: auto;
        }
	}

	.columns {
		display: inline-block;
		height: 100%;
	}

	.container {
		display: block;
		padding: 20px 25px;

		@media screen and (min-width: $mediumMinWidthFoundationBreak) {
            position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			padding: 20px 35px;
        }
	}

	.carousel-content-wrapper {
		height: 100%;
		display: table;
		margin: auto;
	}

	.carousel-content {
		display: table-cell;
		vertical-align: middle;
		height: 100%;
		padding-bottom: 20px;

		img {

			@media screen and (min-width: $mediumMinWidthFoundationBreak) {
				max-width:100%;
				max-height:100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		.slide-title {
			float: left;
			width: 100%;
			@include fontSize(1.2);
			margin-bottom: 12px;
		}

		.slide-summary {
			float: left;
			width: 100%;
			@include fontSize(1.9);
			font-weight: 600;
			margin-bottom: 10px;
			line-height: 25px;
		}

		.slide-button { 
			float: left;
			width: 100%;

			.button {
				display: inline-block;
				padding: 10px;
				margin-bottom: 0;
				@include fontSize(1.8);
				font-weight: bold;
				line-height: 1.6;
				text-align: center;
				vertical-align: middle;
				position: relative;
				cursor: pointer;
				border: 0 none;
				border-radius: 25px;
				white-space: nowrap;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				-o-user-select: none;
				user-select: none;
				box-shadow: none;
				width: 65%;
			}
		}
	}

	.bx-controls-direction a {
		margin-top: -50px;
	}
}

.page-carousel {
	
	h3 {
		margin: 30px 0 20px;
	}

	.bx-viewport {
		@media screen and (max-width: $mediumMinWidthFoundationBreak) {
            background-color: $minsk;
        }
	}

	.carousel-content {
		width: 100%;
		@include background-opacity($minsk, 1);
		color: $white;
		@include fontSize(1.4);
		font-weight: bold;
		line-height: 16px;
		padding: 15px 15px 40px 15px;

		@media screen and (max-width: $smallMinWidthFoundationBreak) {
            min-height: 167px;
        }

		@media screen and (min-width: $mediumMinWidthFoundationBreak) {
            position: absolute;
			bottom: 0px;
			left: 0px;
			@include background-opacity($minsk, 0.8);
        }
	}

	.bx-pager {
		margin: auto;
		position: absolute;
		left: 0;
		bottom: 20px;
		right: 0;
	}

	hr {
		margin: 30px 0px;
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid $minsk;
		padding: 0;
	}
}

.dropdown.dropdown--purple {
    color: white;
    border: 2px solid black;
    background-color: $minsk;
}
