.register .content .rs-form-wrapper.rs-delivery-address {
  border: none;

  #stepHeading {
    text-align: left;
  }

  .address {
    top: 53px;
    left: 10px;

    h3 {
      height: 16px;
      margin-bottom: 9px;
    }

    @media screen and (max-width: 740px) {
      top: auto;
      left: auto;
    }
  }

  .details {
    .same-as-billing {
      padding-top: 2px;
      padding-bottom: 20px;
    }
  }

  #chkSameAsBillingAddress {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0;
    margin-top: -5px;
  }

  input[disabled='disabled'],
  select[disabled='disabled'] {
    background-color: rgb(235, 235, 228);
  }

  .button-row {
    padding-left: 10px;
    padding-right: 5%;
    .button-continue-shopping {
      padding-top: 14px;
    }
    .button-checkout {
      a {
        display: inline-block;
        background-color: #006d77;
        margin-bottom: 0;
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.428571429;
        padding: 12px 24px;
        text-align: center;
        vertical-align: middle;
        position: relative;
        cursor: pointer;
        border: 0 none;
        border-radius: 90px;
        white-space: nowrap;
        -webkit-user-select: none;
      }
    }
  }
}
