.generic-form-container {
    .EditingFormTable {
        margin-top: 20px;
        td {
            border: 0;
            font-size: 1.5rem;
            vertical-align: top;
            // display: block;
            padding-left: 0;
        }
    }
}

.form-page-wrapper {
    .TextBoxField,
    .TextAreaField,
    .CalendarTextBox,
    .EditingFormImagePathTextBox {
        width: 50%;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.42857143;
        color: #555;
        background-color: #FFF;
        border: 1px solid #CCC;
        border-radius: 4px;
        vertical-align: middle;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        padding: 6px 12px;
        font-family: Arial;
    }
    .DropDownField {
        padding: 8px 12px;
        line-height: 1.42857143;
        color: #555;
        background-color: #FFF;
        border: 1px solid #CCC;
        border-radius: 4px;
        vertical-align: middle;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        width: 50%;
        height: 35px;
    }
    .ContentButton {
        background-color: #19B5A5;
        padding: 13px 26px;
        border: none;
        border-radius: 5px;
        font-size: 14.6px;
        font-size: 1.46rem;
        display: inline-block;
        text-decoration: none;
        color: #fff;
        margin-left: 7px;
    }
    .EditingFormImagePathTextBox {
        margin-top: 0px !important;
    }
    .CheckBoxListField,
    .RadioButtonList {
        margin-bottom: 0px;
        tr {
            display: inline-block;
        }
        input {
            margin-right: 7px;
        }
        label {
            margin-right: 10px;
        }
    }
    .EditingFormLabelCell {
        padding-bottom: 0;
    }
    .EditingFormLabel {
        padding-left: 0px;
        vertical-align: top;
    }
    .CalendarTextBox {
        width: 25%;
        margin-right: 15px;
    }
    .InfoLabel {
        color: red;
    }
    .ListBoxField {
        width: 106px;
        height: auto;
        option {
            padding: 5px;
        }
    }
    .CaptchaTable {
        td:first-child {
            display: inline;
            padding: 0;
            font-size: 0;
            input {
                width: 150px;
                font-size: 1.4rem;
                line-height: 1.42857143;
                border: 1px solid #CCC;
                border-radius: 4px;
                vertical-align: middle;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                padding: 7px 12px;
                margin-right: -10px;
            }
        }
        td:nth-child(2) {
            display: inline-block;
            background-color: $silver;
            font-size: 0;
            padding-left: 8px;
            margin-left: 8px;
        }
    }
    .ListBoxField {
        border: 1px solid #CCC;
        border-radius: 4px;
        vertical-align: middle;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
    .FormButton {
        color: #FFF;
        text-decoration: none;
        font-size: 14.56px;
        font-size: 1.456rem;
        background-color: #19B5A5;
        border-radius: 8px;
        padding: 10px 20px;
        margin: 0;
        border: 0 none;
        cursor: pointer;
        outline: none;
    }
    .HiddenButton {
        display: none;
    }
    .Uploader {
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(204, 204, 204);
        min-width: 288px;
        display: table;
        padding: 3px;
    }
    .UploaderLabel {
        margin-right: 5px;
        margin-left: 5px;
    }
    .ErrorLabel,
    .EditingFormErrorLabel {
        color: red;
    }
    #p_lt_ctl03_pageplaceholder_p_lt_ctl00_BizForm_viewBiz_ctl00_SecurityCode_captchaControl_lblSecurityCode {
        display: none;
    }
    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        .RadioButtonList tr,
        .CheckBoxListField tr {
            display: block;
            width: 100%;
        }
        .TextBoxField,
        .TextAreaField,
        .CalendarTextBox,
        .DropDownField {
            width: 100%;
        }
    }
    @media screen and (max-width: 414px) {
        .TextBoxField,
        .TextAreaField,
        .CalendarTextBox,
        .DropDownField {
            width: 70%;
        }
        .UploaderInputFile {
            display: block;
            padding-top: 12px;
            margin-left: 5px;
        }
        .CaptchaTable {
            td:first-child {
                display: block;
            }
            td:nth-child(2) {
                display: block;
                margin-left: 0px;
                width: 150px;
                margin-top: 5px;
                text-align: center;
            }
        }
        .EditingFormErrorLabel {
        	width: 50%;
        }
    }
}