$black: #000;
$white: #FFF;

//Purple
$minsk: #252872;
$jacarta: #252872;

//Orange
$burningOrange: #F28C00;
$tiaMaria: #F28C00;

//Gray
$athensGray: #E6EAEA;
$mildGray: #E6EAEA;
$silver: #E6EAEA;
$gallery: #E6EAEA;
$alabaster: #E6EAEA;
$alto: #E6EAEA;
$lightAthensGray: #E6EAEA;
$silver-dot: #E6EAEA;
$doveGray: #E6EAEA;
$concrete: #E6EAEA;
$midGray: #E6EAEA;
$paleGray: #E6EAEA;
$dimGray: #E6EAEA;
$newRsGray: #E6EAEA;
$diffGrey: #6A6A6D;

//Red
$torchRed: #FF0048;
$redRibbon: #F90E46;
$monza: #B30032;
$rose: #FF006A;
$roman: #D9534F;
$valencia: #D43F3A;

//Yellow 
$lemon: #F28C00;
$saffron: #F28C00;
$ripe-lemon: #F28C00;

//Green
$mountainMeadow: #25B8A6;
$java: #25B8A6;
$genoa: #25B8A6;
$mountainMeadow2: #25B8A6;
$persianGreen: #25B8A6;
$turquoise: #25B8A6;

//Black
$cinder: #16131B;
$mineShaft: #333;
$emperor: #555;

//Social Media Colours
$youtubeColour: #D40000;
$facebookColour: #35579F;
$twitterColour: #2ABAE7;

