
  $rs-icons-arrow-down: "\f101";

  $rs-icons-arrow-left: "\f102";

  $rs-icons-arrow-right: "\f103";

  $rs-icons-arrow-up: "\f104";

  $rs-icons-basket: "\f105";

  $rs-icons-bullet-star-full: "\f106";

  $rs-icons-cross: "\f107";

  $rs-icons-email: "\f108";

  $rs-icons-email-background: "\f109";

  $rs-icons-email-background-alt: "\f10a";

  $rs-icons-facebook: "\f10b";

  $rs-icons-facebook-alt: "\f10c";

  $rs-icons-menu: "\f10d";

  $rs-icons-phone: "\f10e";

  $rs-icons-phone-alt: "\f10f";

  $rs-icons-quote-close: "\f110";

  $rs-icons-quote-open: "\f111";

  $rs-icons-rs-download: "\f112";

  $rs-icons-search: "\f113";

  $rs-icons-twitter: "\f114";

  $rs-icons-twitter-alt: "\f115";

  $rs-icons-youtube: "\f116";

  $rs-icons-youtube-alt: "\f117";


@font-face {
  font-family: "rs-icons";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("~@fonts/rs-src/dist/rs-icons.eot");
  src: url("~@fonts/rs-src/dist/rs-icons.eot?#iefix") format("embedded-opentype"), url("~@fonts/rs-src/dist/rs-icons.woff2") format("woff2"), url("~@fonts/rs-src/dist/rs-icons.woff") format("woff"), url("~@fonts/rs-src/dist/rs-icons.ttf") format("truetype"), url("~@fonts/rs-src/dist/rs-icons.svg#rs-icons") format("svg");
}

%rs-icons {
display: inline-block;
  font-family: "rs-icons";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.rs-icons {
  @extend %rs-icons;
}

.rs-icons-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.rs-icons-xs {
  font-size: 0.75em;
}

.rs-icons-sm {
  font-size: 0.875em;
}

.rs-icons-1x {
  font-size: 1em;
}

.rs-icons-2x {
  font-size: 2em;
}

.rs-icons-3x {
  font-size: 3em;
}

.rs-icons-4x {
  font-size: 4em;
}

.rs-icons-5x {
  font-size: 5em;
}

.rs-icons-6x {
  font-size: 6em;
}

.rs-icons-7x {
  font-size: 7em;
}

.rs-icons-8x {
  font-size: 8em;
}

.rs-icons-9x {
  font-size: 9em;
}

.rs-icons-10x {
  font-size: 10em;
}

.rs-icons-fw {
  text-align: center;
  width: 1.25em;
}

.rs-icons-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.rs-icons-pull-left {
  float: left;
}

.rs-icons-pull-right {
  float: right;
}

.rs-icons.rs-icons-pull-left {
  margin-right: 0.3em;
}

.rs-icons.rs-icons-pull-right {
  margin-left: 0.3em;
}


.rs-icons-arrow-down::before {
    content: "\f101";
}

.rs-icons-arrow-left::before {
    content: "\f102";
}

.rs-icons-arrow-right::before {
    content: "\f103";
}

.rs-icons-arrow-up::before {
    content: "\f104";
}

.rs-icons-basket::before {
    content: "\f105";
}

.rs-icons-bullet-star-full::before {
    content: "\f106";
}

.rs-icons-cross::before {
    content: "\f107";
}

.rs-icons-email::before {
    content: "\f108";
}

.rs-icons-email-background::before {
    content: "\f109";
}

.rs-icons-email-background-alt::before {
    content: "\f10a";
}

.rs-icons-facebook::before {
    content: "\f10b";
}

.rs-icons-facebook-alt::before {
    content: "\f10c";
}

.rs-icons-menu::before {
    content: "\f10d";
}

.rs-icons-phone::before {
    content: "\f10e";
}

.rs-icons-phone-alt::before {
    content: "\f10f";
}

.rs-icons-quote-close::before {
    content: "\f110";
}

.rs-icons-quote-open::before {
    content: "\f111";
}

.rs-icons-rs-download::before {
    content: "\f112";
}

.rs-icons-search::before {
    content: "\f113";
}

.rs-icons-twitter::before {
    content: "\f114";
}

.rs-icons-twitter-alt::before {
    content: "\f115";
}

.rs-icons-youtube::before {
    content: "\f116";
}

.rs-icons-youtube-alt::before {
    content: "\f117";
}

