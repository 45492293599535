.basketNotificationPanel {
  display: none;
  width: 222px;
  background: #006d77;
  border-radius: 5px;
  padding-top: 17px;
  padding-left: 17px;
  padding-right: 20px;
  padding-bottom: 23px;

  position: fixed;
  bottom: -10px;
  right: 44px;
  z-index: 100;

  .messageClose {
    // @include icon($cross, 12px, $black);

    font-family: 'Oxygen';
    @include fontSize(1.7);
    font-weight: bold;
    color: $genoa;
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  .item-cover {
    display: none;
  }

  .messageText {
    display: inline-block;
    font-family: 'Oxygen';
    font-weight: bold;
    @include fontSize(1.6);
    color: $white;
    clear: both;
    width: 174px;
    padding-bottom: 16px;
  }

  .basket-items {
    font-family: 'Oxygen';
    @include fontSize(1.6);
    line-height: 20px;
    color: $white;
    padding-bottom: 16px;
  }

  a {
    text-decoration: none;

    .goToCheckout {
      position: relative;
      border-radius: 5px;
      width: 185px;
      height: 48px;
      background: $genoa;

      padding-top: 18px;
      padding-left: 18px;
      padding-right: 19px;

      font-family: 'Oxygen';
      font-weight: bold;
      @include fontSize(1.6);
      color: $white;

      &:after {
        content: '\BB';
        display: inline-block;
        position: absolute;
        top: 14px;
        right: 18px;
        @include fontSize(2.1);
      }
    }
  }
}
