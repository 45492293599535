.partners-section {
  padding: 20px 0px 40px;
  border-top: 2px solid #a5a5a6;

  h6 {
    font-size: 28px;
    font-weight: normal;
    color: $minsk;
    text-transform: uppercase;
    margin: 0;
    margin-top: 70px;
    text-align: center;
  }

  .row {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .columns {
    width: 100%;
    flex: 1 0 16%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    @media screen and (max-width: 880px) {
      width: 33%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.partners-section-title {
  text-align: center;
  color: $mineShaft;
}

.partners-section-link {
  padding-top: 30px;
  text-align: center;

  a {
    display: inline-block;
    background-color: $burningOrange;
    border-radius: 5px;
    color: $minsk;
    padding: 10px 18px;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      background-color: $minsk;
      color: $burningOrange;
    }
  }
}
