.account-email-preferences {
	.register {
		position: relative;
		.error-message {
			display: none;
		}

		.working {
	        display: block;
	        position: absolute;
	        top: 0;
	        left: 0;
	        right: 0;
	        bottom: 0;
	        background-color: #fff;
	        opacity: 0.8;
	        z-index: 100;
	        &:before {
	            content: '';
	            display: block;
	            width: 32px;
	            height: 32px;
	            top: 50%;
	            left: 50%;
	            margin-left: -16px;
	            margin-top: -16px;
	            background-image: url(/images/general/loading.gif);
	            position: absolute;
	            
	        }

	        &.hidden {
	            display: none;
	        }
	    }
	}
}

.contact-profiles {
	padding-top: 20px;
	.profile-category {
		

	}

	.category-name {
		display: inline-block;
		color: #ff0048;
		font-weight: bold;
		font-size: 16px;
		padding-bottom: 10px;
	}

	.item-container {

	}

	.col1Subjects,
	.col2Subjects,
	.col3Subjects {
		vertical-align: top;
		display: inline-block;
		width: 33%;
	}

	@media screen and (max-width: 570px) {
		.col1Subjects,
		.col2Subjects {
			width: 50%;
		}
		.col3Subjects {
			width: 100%;
		}
	}

	@media screen and (max-width: 385px) {
		.col1Subjects,
		.col2Subjects {
			width: 100%;
		}
	}


	.profile-item.category {
		padding-left: 10px;
		.profile-name {
			color: #ff0048;

		}
	}
}

.button-row.submit-profiles {
    margin-top: 10px;
	overflow: hidden;
	padding-bottom: 20px;
	.button-checkout, .button-skip, .button-unsubscribe {
		float: unset !important;
	}
}

.tncs-error {
    display: block;
}

label[for="tncs-checkbox"] {
    font-size: 11pt;
}