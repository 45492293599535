.my-series-page {
	background-attachment: fixed;
	
	.heading-area {
		border-bottom: none;
	}

	.series-page-content {
		background-color: #fff;
		padding: 20px 30px;
		border-radius: 10px;
		margin-bottom: 40px;
	}

	.banner {
		overflow: hidden;
		@media screen and (max-width: 40.0625em) {
			height: 284px;
		}

		.banner-logo {
			position: absolute;
			right: auto;
			left: 10px;
		}
	}
}

@media screen and (max-width: 768px) {
	.my-series-page {
		.banner {
			.banner-logo {
				left: 10px;
				padding-right: 20px; // I don't understand this, but it works.
				top: 20px;
				bottom: auto;
			}
		}
	}
}

.my-series-content-top {
	margin: 20px 0px;
}

.my-series-content-wrapper {
	background-color: $concrete;
	padding: 44px 0;

	.container {
		
		.my-series-content {
			@include clearfix();
			background: #FFF;
			padding: 30px 25px 46px;
			border-radius: 13px;

			.my-series-row {
				margin-bottom: 30px;

				div.item-listing {
				}
			}

			.my-series-image-left {
				float: left;

				img {
					max-width: 95%;
					border-left: 0 none;
					border-right: 1px solid $mountainMeadow2;
					border-bottom: 0 none;
					border-top: 0 none;
					padding: 0;

					@media screen and (max-width: $mediumMinWidthFoundationBreak) {
						& {
							border-right: 0 none;
						}
					}
				}
			}

			.my-series-text-right {
				float: right;
				padding: 40px 15px;
			}
		}
	}
}

.my-series-navigation {
    background-color: #eceff4;
    text-align: left;

    .my-series-navigation-title {
        color: $minsk;
        font-size: 24px;
        border-bottom: 1px solid $minsk;
        padding: 15px;
    }

    .my-series-navigation-list {
        padding: 0;
        list-style-type: none;
        padding-bottom: 30px;
    }

    .my-series-navigation-item {

    }

    .my-series-navigation-link {
        display: block;
        color: $minsk;
        padding: 2px 15px;

        &.active,
        &:hover,
        &:active {
            background: #cad2df;
        }
    }
}