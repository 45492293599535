.product-contents-cont {
	border-top: 1px solid $minsk;
	padding-top: 0 !important;
	margin-top: 20px;
	max-width: 680px;

	table {
		width: 680px!important;

       	@media screen and (max-width: $largeMinWidthFoundationBreak) {
            width: auto!important;
        }
		
	}

	ol {
		margin-top: 0;
		margin-bottom: 10px;

		li {
			list-style-type: decimal;
			list-style-position: inside;
		}
	}

	ul {
		margin-left: 41px;

		li {
			display: list-item;
			list-style-type: disc;
		}
	}

	.product-contents {

		tr {
			&:first-child {
				font-weight: normal;
				color: #ff6933;
				font-size: 1.2em;

				strong {
					border-bottom: 1px solid #999!important;
					width: 95%;
					display: block;
					padding-bottom: 10px;
					font-weight: normal;
				}
			}
		}

		td {
			padding: 8px;
		}
	}
}

