.list-layout-container {
	width: 100%;

	@media screen and (max-width: 768px) {
		background-color: $newRsGray;
	}

	.list-layout-wrapper {
		display: flex;
		padding-top: 30px;
		padding-bottom: 30px;
		position: relative;

		@media screen and (max-width: 768px) {
			border-top: 2px solid $newRsGray;
			background-color: $white;
			padding-bottom: 5px;

			&.is-open {
				.list-layout-accordion-content {
					max-height: 1000px;
					opacity: 1;
					padding-bottom: 30px;
				}

				.list-layout-text {
					&:before {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	.list-layout-image {
		width: 256px;
		min-width: 256px;
		display: flex;
		
		@media screen and (min-width: 768px) {
			justify-content: center;
		}

		img {
			max-width: 80%;
			align-self: flex-start;

		}

		@media screen and (max-width: 768px) {
			width: 150px;
			min-width: 150px;
			cursor: pointer;
		}

		@media screen and (max-width: 480px) {
			width: 100px;
			min-width: 100px;
		}
	}

	.list-layout-text {
		max-width: 900px;
		padding-right: 50px;
		position: relative;

		@media screen and (max-width: 480px) {
			padding-right: 25px;
		}

		&:before {
			@media screen and (max-width: 768px) {
				width: 14px;
				height: 7px;
				content: '';
				display: block;
				background-image: url('/images/Rising-Stars/core/svg/svg--chevron-down.svg');
				position: absolute;
				right: 25px;
				top: 25px;
				transition: transform .4s ease-in-out;
			}
		}
	}

	.list-layout-accordion-title {
		@media screen and (max-width: 768px) {
			cursor: pointer;
		}
	}

	.list-layout-subtitle {
		font-size: 12px;
		color: $java;
		display: block;
		margin-bottom: 10px;
	}

	.list-layout-title {
		color: $minsk;

		h4 {
			font-weight: normal;
			margin: 0px;
			padding-bottom: 15px;
		}
	}

	.list-layout-external-link {
		font-weight: bold;

		&:after {
			padding-left: 0.31em;
			content: '\000BB';
			display: inline-block;
			color: $torchRed;
		}
	}

	.list-layout-accordion-content {
		@media screen and (max-width: 768px) {
			width: calc(100% + 150px);
			max-height: 0;
			margin-top: 25px;
			margin-left: -150px;
			overflow: hidden;
			opacity: 0;
			transition: max-height .4s ease-in-out, opacity .4s ease-in-out;
		}

		@media screen and (max-width: 480px) {
			width: calc(100% + 100px);
			margin-left: -100px;
		}
	}
}