.account-email-preferences {
	background: #FFF none repeat scroll 0% 0%;
	padding: 30px 25px 30px;
	border-radius: 13px;

	.register {
		padding-top: 0;
	}

	h1 {
		font-size: 22px;
	}

	.button-skip {
		display: none;
	}

	.button-row.submit-profiles {
		padding-bottom: 0px;
	}

	.item-container {
		min-height: 100px;
	}
}