main {
	overflow: hidden;
}

.container {
	// max-width: 960px;
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 10px;
}

.content {
	padding: 0 10px;
}

aside {
	vertical-align: top;
	padding: 0 10px;
}

.cc_message {
	color: #fff;
}