$brk-free-stuff-single-column: 496px;


.free-stuff-page {

  iframe {
    max-width: 100%;
  }

  .free-stuff-image {
    float: left;
    border: 3px solid #f0f3f6;
    margin-right: 30px;
    margin-bottom: 30px;
    max-width: 226px;
  }

  ul {
    font-size: 1.5rem;
    display: block;
    list-style-type: disc;
    padding-left: 22px;
    padding-bottom: 1rem;

    li {
      display: list-item;
      padding-bottom: 0.7rem;
    }
  }

  table[border="0"] {
    td {
      border: none;
    }
  }

}

.free-stuff-button-row-top {
  overflow: hidden;
}

.button-toggle-free-stuff-favourite {
  color: #fff;
  background-color: #19b5a5;
  border-radius: 20px;
  float: right;
  margin-bottom: 20px;
  display: inline-block;
  padding: 14px;

  &:after {
    padding-left: 0.31em;
    content: '\000BB';
    display: inline-block;
  }
}


.free-stuff-back-to-archive-cont {
  text-align: right;
  padding-bottom: 40px;

  a {}
}

.free-downloads {
  display: block;
  clear: both;
  @include justifyLayout();

  .free-download {
    vertical-align: top;
    width: 230px;
    min-height: 40px;
    position: relative;
    text-align: left;
    padding-left: 30px;
    letter-spacing: -1px;
    margin-bottom: 15px;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
      min-height: inherit;
      max-height: inherit;
      padding: 0;
    }


    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      margin-top: -10px;
      top: 50%;
      left: 0;

      &:before {
        @include icon($rs-icons-rs-download, 20px, #ff0048);
      }
    }

    .text {
      display: inline-block;
      letter-spacing: normal;
      font-size: 1.5rem;
      vertical-align: middle;
      line-height: 1.2;
    }

    &:hover .text {
      text-decoration: underline;
    }
  }

  .free-download-fake {
    vertical-align: top;
    width: 230px;
    min-height: 0px;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
  }

  .side-widget-promo-panel {
    margin-top: 10px;
  }
}

@media screen and (max-width: $brk-free-stuff-single-column) {
  .free-stuff-page {
    .free-stuff-image {
      display: block;
      float: none;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.free-stuff-subject-page {
  width: 75%;
  float: left;

  .free-stuff-archive-page {
    padding-top: 0;
  }
}

.free-stuff-series-page {
  .container {
    aside {
      margin-bottom: 20px;
    }
  }
}


.free-stuff-login-wrapper {
  padding: 40px 0px 20px 0px !important;


  .login-panel {
    background-color: #fff;
    border: solid 1px #19B5A5;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    padding: 20px;
    overflow: visible;

    .login-copy {
      width: 55%;
      display: inline-block;
      font-size: 18px;
      color: $minsk;
      font-weight: bold;
      vertical-align: middle;
    }

    .login-login-button {
      display: inline-block;
      margin-left: 6px;
      vertical-align: middle;


    }

    .login-register-button {
      display: inline-block;
      margin-left: 6px;
      vertical-align: middle;

      .button-container {
        background-color: #ff0048;
      }
    }
  }

  @media screen and (max-width: 960px) {

    .login-panel {
      text-align: right;

      .login-copy {
        display: block;
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 840px) {

    .login-panel .login-copy {
      width: 100%;
    }
  }

  @media screen and (max-width: 490px) {

    .login-panel {
      text-align: center;

      .login-copy {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 370px) {
    .login-login-button {
      margin-bottom: 10px;
    }
  }
}
