.home-page-featured-widgets {
	@include justifyLayout;  
	position: relative;
	margin-bottom: 28px;
}

.all-featured-widgets {
	width: 100%;
}

