.basket-upsell {
  position: fixed;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;

  &--active {
    display: flex;
  }

  &-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    padding: 30px 25px;

    &__close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 16px;
      height: 16px;
      background-image: url('/images/general/svg/svg--close.svg');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    &__logo {
      pointer-events: none;
      display: block;
      width: 100%;
      max-width: 160px;
      margin: 0 auto 25px auto;
    }

    &__summary {
      pointer-events: none;
      text-align: center;
    }

    &__cta {
      width: 100%;
      border-radius: 5px;
      text-align: center;
      padding: 15px;
      font-weight: bold;
    }

    &--mark {
      background-color: $brand-primary;

      .basket-upsell-inner__summary {
        color: $brand-white;
      }

      .basket-upsell-inner__cta {
        background-color: $brand-yellow;
        color: $brand-white;
      }
    }
  }
}
