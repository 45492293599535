.rs-faqs {
  padding: 50px 30px;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-family: 'Oxygen', sans-serif;
    color: $brand-primary;
    font-size: 32px;
    line-height: 30px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 20px;
    opacity: 0;
    transform: translateY(-25px);
    transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    .animated & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__item-container {
    width: 100%;
    max-width: 926px;
  }
}

.accordion {
  position: relative;
  display: block;

  &__item {
    display: block;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateY(-25px);
    transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    .animated & {
      opacity: 1;
      transform: translateY(0);
    }

    &-header {
      position: relative;
      display: block;
      border: 0;
      background-color: $brand-primary;
      font-family: 'Oxygen', sans-serif;
      font-size: 18px;
      font-weight: 600;
      color: $brand-white;
      line-height: 21px;
      padding: 18px 72px 18px 18px;
      outline: 0;
      text-align: left;
      width: 100%;
      z-index: 1;
      cursor: pointer;

      .is-active & {
        color: $brand-primary;
        background-color: $brand-yellow;
      }
    }

    &-header-end {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: $brand-white;
      line-height: 21px;

      .is-active & {
        color: $brand-primary;
      }
    }

    &-header-icon {
      width: 17px;
      height: 10px;
      fill: $brand-white;
      stroke: $brand-white;
      margin-left: 5px;

      .is-active & {
        fill: $brand-primary;
        stroke: $brand-primary;
      }
    }

    &-content {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: background-color 0.3s ease-in-out, border-radius 0.3s ease-in-out, max-height 0.6s ease-in-out;

      &-inner {
        display: block;
        padding: 10px 30px 0 10px;

        @media (min-width: $screen-md) {
          padding: 0px 42px 42px 24px;
        }

        ul {
          text-align: left;
        }
      }

      p {
        font-family: 'Oxygen', sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: $brand-black;
        font-weight: 200;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .is-active & {
        background: rgba($brand-yellow, 0.75);

        @supports not (display: grid) {
          // This IE fallback looks to work regardless of non-ie using the CSS variable
          max-height: 100%;
        }

        @supports (display: grid) {
          max-height: var(--height);
        }
      }
    }
  }
}
