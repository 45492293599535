#conflicts {
	max-width: 960px;
	margin: 0 auto;
	padding: 60px 10px;

	#order-conflicts-container {
		padding-bottom: 20px;
	}

	.statusLabel {
		font-size: 14px;
		line-height: 1.3;
		margin-left: 0;
	}

	.summary-status-rows {
		text-align: center;
		.summary-status-rows {
			display: inline-block;
			float: none;

		}
	}

	.sub-total {
		float: none;
		max-width: none;
		float: left; 

		@media screen and (max-width: $mediumMinWidthFoundationBreak) {
			clear: both;
			text-align: left;
		}
		.price {
			float: none;
			padding-left: 10px;
		}
	}


	.button-row {
		padding-top: 30px;
		text-align: center;

		.button-checkout {
			float: right;
			@media screen and (max-width: 369px) {
				float: left;
			}
		}

		.button-continue-shopping {
			float: left;
			padding-top: 12px;
			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				padding-top: 25px;
			}
			@media screen and (max-width: 641px) {
				padding-top: 21px;
			}
			@media screen and (max-width: 369px) {
				padding-top: 21px;
			}
		}

		.button.status {
			padding-top: 15px;
			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				padding-top: 23px;
			}
			@media screen and (max-width: 468px) {
				float: left; 
				padding-top: 45px;
				padding-left: 10px;
			}
			@media screen and (max-width: 369px) {
				display: block;
				clear: both;
				padding-top: 20px;
			}
		}
	}
}