.user-details-cont {
	position: relative;
}


.help-button-cont {

}

.help-button {
	position: relative;
	padding-bottom: 10px;
	display: inline-block;
	margin-bottom: 10px;
	overflow:hidden;
	border: none;
    width: 176px;
    padding: 5px;
    border-radius: 30px;
    background: #25B9AA;
    cursor: pointer;
    outline: none;
    color: $white;
    font-weight: bold;

    text-align: center;
    padding: 13px;



	.help-button-tooltip {
		position: absolute;
		left: 0;
		top: 100%;
		background-color: #fff;
		border-radius: 5px;
		padding: 5px;
		line-height: 1.3;
		border: 1px solid #F0F3F6;
		width: 250px;
		color: #000;
		z-index: 200;
		-webkit-box-shadow: -1px 2px 17px -6px rgba(0,0,0,0.4);
		-moz-box-shadow: -1px 2px 17px -6px rgba(0,0,0,0.4);
		box-shadow: -1px 2px 17px -6px rgba(0,0,0,0.4);
		-webkit-transition: height 200ms ease-out;
		transition: opacity 200ms ease-out;
		opacity: 0; 
		height: 0;
		font-weight: normal;
	}

	&:hover {
		overflow: visible;
	}

	&:hover .help-button-tooltip {
		display: block;
		
		opacity: 1;
		height: auto;
		
	}

	.help-button-tooltip-fake-link {
		color: #ff0048;
		text-decoration: underline;
		display: block;
	}

	@media screen and (min-width: 440px) {
		position: absolute;
		top: 43%;
		margin-top: -11px;
		right: 0;

		.help-button-tooltip {
			right: 0;
			left: auto;
		}
	}
}



.dashboard-header {
}

.dashboard-user-details {
	padding: 25px 0;

	@media screen and (min-width: 440px) {
		padding: 53px 0;		
	}
}

.dashboard-user-name {
	display: block;
	@include fontSize(2.1);
	font-weight: bold;
	line-height: 1.2;
}

.dashboard-user-institution {
	display: block;
	@include fontSize(1.5);
	line-height: 1.2;
}

.my-dashboard-section-wrapper {	
	padding-right: 20px;
	@media screen and (max-width: $mediumMinWidthFoundationBreak) {
		padding-right: 0px;
	}
}

.dashboard-content {
	background-color: $concrete;
	padding: 44px 0;


	.accountdetails {
		background: $white;
		padding: 20px;
		border-radius: 13px;


		@media screen and (max-width: $mediumMinWidthFoundationBreak) {
			padding: 30px 15px 20px;
		}

		h2 {
			margin-top: 0;
			@include fontSize(2.2);
		}

		h3 {
			@include fontSize(1.5);
			color: $doveGray;
		}

		.changeDetailsContainer {
			padding: 40px 0 60px;
		}

		.content {
			padding: 0;
			img {
				padding-bottom: 14px;
			}
		}

		.details {
			max-width: 643px;
			display: inline-block;
			line-height: 41px;

			@media screen and (max-width: $largeMinWidthFoundationBreak) {
				width: 50%;
			}

			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				display: block;
				width: 100%;
				margin: 10px 0;
				line-height: 1.1em;
			}
		}

		.row {
			@include verticalAlignmentFix(middle);

			max-width: 100%;
			border: 1px solid $alto;
			border-width: 1px 0 0 0;
			padding: 14px 12px;

			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				padding: 14px 10px;
			}

			&:last-child {
				border-width: 1px 0px;
			}

			&.active {
				background: $concrete; 
			}
		}

		.button {
			float: right;

			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				float: none;
			}

			.button-container {
				display: inline-block;
				@include fontSize(1.45);
				margin: 0px;
				line-height: 1.475;
				cursor: pointer;
				padding: 7px 20px;
				font-weight: bold;
				vertical-align: middle;
				border: 0px none;
				border-radius: 9px;
				white-space: nowrap;
				-webkit-user-select: none;
				background-color: #19B5A5;
				width: 117px;
				height: 36px;
				text-align: center;

				a {
					color: $white;
					display: block;
					font-weight: normal;
				}
			}
		}

		.label {
			display: inline-block;
			max-width: 110px;
			width: 100%;
			font-weight: bold;
			margin-right: 23px;

			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				display: block;
				margin: 10px 0;
				min-height: 0;
				line-height: 1.4em;
			}
		}

		.subjectTitleArea {
			display: none;
		}

		.change {
			display: none;
			position: relative;
			background: $concrete;
			padding: 20px 17px 55px 14px;

			.details {
				max-width: 100%;
				line-height: 1.4em;
				width: 62%;

				@media screen and (max-width: $mediumMinWidthFoundationBreak) {
					width: 100%;
					max-width: 100%;
				}
			}

			h2 {
				@media screen and (max-width: $mediumMinWidthFoundationBreak) {
					width: 90%;
				}
			}

			.error-text {
				text-align: left;
				color: $mountainMeadow;
				display: block;
				margin-right: -25px;
			}

			.changeYourNameContainer {
				@include justifyLayout();
				@include clearfix();

				.row {
					width: 43%;
					box-sizing: border-box;

					@media screen and (max-width: $largeMinWidthFoundationBreak) {
						width: 36%;
					}

					&:first-child {
						@media screen and (max-width: $largeMinWidthFoundationBreak) {
							margin-right: 29px;
						}

						@media screen and (max-width: $mediumMinWidthFoundationBreak) {
							margin-right: 0;
						}
					}

					@media screen and (max-width: $mediumMinWidthFoundationBreak) {
						display: block;
						width: 100%;
						margin: 10px 0;
					}

					.label {
						min-height: 34px;
						line-height: 34px;
						float: left;
						margin: 0;

						@media screen and (max-width: $largeMinWidthFoundationBreak) {
							display: block;
							float: none;
							width: 100%;
						}
					}

					.details {
						float: left;
						margin: 0px 26px;

						@media screen and (max-width: $largeMinWidthFoundationBreak) {
							margin: 0;
							float: none;
							display: block;
							width: 100%;
						}
					}
				}
			}

			.error-row {
				min-height: 55px;
			}

			.changeYourPasswordContainer {
				@include clearfix();
				@include justifyLayout();

				.row {
					width: 43%;
					box-sizing: border-box;
					float: left;

					&:nth-child(odd) {
						@media screen and (max-width: $largeMinWidthFoundationBreak) {
							margin-right: 29px;
						}

						@media screen and (max-width: $mediumMinWidthFoundationBreak) {
							margin-right: 0;
						}
					}

					@media screen and (max-width: $mediumMinWidthFoundationBreak) {
						display: block;
						width: 100%;
						margin: 10px 0;
					}

					.label {
						line-height: 1.4em;
						max-width: 100px;
						width: 100%;
						text-align: left;
						float: left;
						margin: 0;

						@media screen and (max-width: $largeMinWidthFoundationBreak) {
							float: none;
							max-width: 100%;
						}
					}

					.details {
						width: 62%;
						float: left;
						margin: 0 17px;

						@media screen and (max-width: $largeMinWidthFoundationBreak) {
							float: none;
							width: 100%;
							margin: 0;
						}
					}

					.details.text.email {
						height: 34px;
						text-align: left;

						@media screen and (max-width: $largeMinWidthFoundationBreak) {
							height: auto;
						}

						@media screen and (max-width: $mediumMinWidthFoundationBreak) {
							margin-top: 10px;
						}
					}
				}

				.button {
					float: right;

					@media screen and (max-width: $mediumMinWidthFoundationBreak) {
						display: block;
						float: none;
					}
				}
			}

			.row {
				border: none;
				display: inline-block;
				max-width: 100%;
				padding: 0;
				width: auto;
				margin: 10px 0;

				@media screen and (max-width: $mediumMinWidthFoundationBreak) {
					display: block;
					width: 100%;
				}

				.label {
					max-width: 100%;
					width: auto;
				}
			}

			.button {
				float: right;
				display: inline-block;
				margin: 10px 0;

				@media screen and (max-width: $largeMinWidthFoundationBreak) {
					float: none;
					display: block;
					width: 100%;
				}

				.button-container {
					background: $torchRed;

					@media screen and (max-width: $largeMinWidthFoundationBreak) {
						float: right;
					}

					@media screen and (max-width: $mediumMinWidthFoundationBreak) {
						float: none;
					}
				}
			}

			input {
				@include inputStyling;
				border: 1px solid $doveGray;
				width: 100%;
			}

			.button-container.button-back {
				display: none;
			}

			.button-back {
				display: inline-block;
				position: absolute;
				top: 21px;
				right: 18px;
				font-weight: bold;
				text-decoration: none;
			}
		}

		.changeYourAddress {
			position: relative;
			background: #E6EAEA;
			padding: 20px 17px 55px 14px;

			h2 {
				margin-bottom: 20px;
			}

			.changeYourAddressContainer {
				text-align: justify;

				@media screen and (max-width: $breakpoint-medium) {
					width: 100%;
				}

				&.rs-form-wrapper {
					margin-bottom: 0;
					position: static;
				}

				.left {
					width: 45%;
					float: left;
				}

				.right {
					width: 45%;
					float: right;
				}

				span.label {
					display: block;
					letter-spacing: normal;
					margin: 0 0 10px 0;
					width: 100%;
					max-width: 100%;
				}

				input, select {
					padding: 5px;
					width: 100%;
					box-sizing: border-box;
					font-size: 16px;

					display: block;
    				width: 100%;
    				font-size: 14px;
    				font-size: 1.4rem;
    				line-height: 1.42857143;
    				color: #555;
    				background-color: #fff;
    				border: 1px solid #E6EAEA;
    				border-radius: 4px;
    				vertical-align: middle;
    				box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    				transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    				padding: 6px 12px;
				}

				.error-text {
					display: inline;
				}

				.button-container {
					margin-top: 10px;
				}

				@media screen and (max-width: $breakpoint-large) {
					.left, .right, input {
						width: 100%;
						float: none;
					}

					input {
						width: 100%;
					}
				}
			}
		}
	}

	.my-dashboard-content {
		width: 100%;
		@include clearfix();
		aside {
			padding: 0;
		}
		.container {
			margin-left: 0;
			padding-left: 0;
			padding-right: 0;
		}
		.rs-pager {
			margin: -22px 0px 30px 0px;
		}
		.item-listing-image-container {
			padding: 5px;

			img {
				padding-bottom: 0px;
			}
		}
		
		.columns {
			margin-left: 0;
			padding-left: 0;
		}

		.medium-10 {
			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				display: none;
			}
		}
		a.see-all-free-stuff {

			font-weight: bold;
			&:after {
				padding-left: 0.31em;
				content: '\000BB';
				display: inline-block;
				
			}
		}
	}
	.blog-posts-widget {
		margin-top: 0px!important;
		background-color: #E1E1E1;
		margin-bottom: 20px;



		.cta-get-more-free-stuff {
			font-weight: bold;
			&:after {
				padding-left: 0.31em;
				content: '\000BB';
				display: inline-block;
				
			}
		}
	}
}

.dashboard-navigation {
	border-top: 1px $alto solid;
}

.dashboard-navigation-item {
	position: relative;
	color: $mountainMeadow;
	display: inline-block;
	padding: 20px 0;
	margin-right: 49px;
	line-height: 20px;

	&.current-dashboard-item {
		font-weight: bold;
		
		&:after {
			content:'';
			position: absolute;
			bottom: -13px;
			right: 50%;
			margin-right: -14px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 13px 14px 0 14px;
			border-color: $white transparent transparent transparent;

			@media screen and (max-width: $mediumMinWidthFoundationBreak) {
				display: none;
			}
		}
	}
}

.main-my-dashboard-container {
	.accountdetails {
		&:not(:first-child) {
			margin-top: 20px;
		}
	}
}

.my-dashboard-free-stuff {
	margin-bottom: 20px;
	a.free-stuff-page-favourite-listing {
		width: 32%;
		@media screen and (max-width: 961px) {
			width: 49%;
		}

		@media screen and (max-width: 799px) {
			width: 100%;
		}
	}
}