﻿.rs-pager {
	width: 100%;
	float: left;
	text-align: center;
	color: $black;
	font-weight: 700;
	@include fontSize(2);
	margin: 20px 0px 30px 0px;

	a {
		@include fontSize(2);
		color: $black;
	}

	.pager-item-spacing {
		margin: 0 7px;
		display: inline-block;
	}

	.pager-navigation {
		margin: 0px 12px;
	}

	.current-page {
		display: inline-block;
		height: 33px;
		width: 33px;
		line-height: 33px;
		-moz-border-radius: 30px;
		border-radius: 30px;
		background-color: $lemon;
		text-align: center;
		margin: 0px 2px;
	}

}

.rs-pager-series-page {
	margin-top: 42px;
	margin-bottom: 38px;
}