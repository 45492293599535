.grid-layout-container {

	.grid-layout-wrapper {
		width: 49%;
		display: inline-block;
		min-height: 175px;

		@media screen and (max-width: 555px) {
			width: 100%;
		}

		.grid-layout-description {
			display: block;
			padding-bottom: 26px;
			color: $brand-primary;
		}

		.grid-layout-text {
			font-weight: bold;
			width: 50%;
			display: inline-block;
			vertical-align: top;
			line-height: 18px;
		}

		.grid-layout-external-link { 
				&:after {
				padding-left: 0.31em;
				content: '\000BB';
				display: inline-block;
				color: $torchRed;
			}
		}

		.grid-layout-image {
			width: 40%;
			vertical-align: top;
			display: inline-block;
			padding-right: 15px;

			img {
				width: 130px;
			}
		}
	}
}