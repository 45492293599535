.favourite-free-stuff-page {
	background-color: #fff;
	padding: 30px 25px 46px;
	border-radius: 13px;

	h1 {
		font-size: 22px;
	}
}

.free-stuff-favourites-list {
    position: relative;

    text-align: justify;
    &:after {
    	width: 100%;
    	display: inline-block;
    	content: '';
    }
}


a.free-stuff-page-favourite-listing {
	width: 220px;
	display: inline-block;
	vertical-align: top;
	height: 154px;
	position: relative;
	padding-left: 69px;
	padding-bottom: 20px; 
	padding-right: 30px;

	.free-stuff-page-icon-cont {
		position: absolute;
		top: 0;
		left: 0;
		text-align: right;
	}

	.free-stuff-page-text-cont {
		display: block;

		.free-stuff-page-title {
			text-align: left;
			display: inline-block;
			line-height: 1.4;
			color: #6a6a6d;
		}

	}

	.free-stuff-page-cta {
		display: inline-block;
		padding-top: 7px;
		color: #ff0048;
		font-family: 'Oxygen', sans-serif;

		&:after {
			padding-left: 0.31em;
			content: '\000BB';
			display: inline-block;
		}
	}

	&:hover {
		.free-stuff-page-cta {
			text-decoration: underline;
		}
	}

	img {
		width: 56px;
	}

	@media screen and (max-width: 971px) {
		width: 270px;
		height: 130px;
	}

	@media screen and (max-width: 900px) {
		width: 360px;
		height: 110px;
	}

	@media screen and (max-width: 808px) {
		width: 300px;
	}

	@media screen and (max-width: 688px) {
		width: 100%;
	}

}