﻿.order-history-panel {
  position: relative;

  &.accountdetails {
    .subjectTitleArea {
      display: block;
      @include fontSize(2.2);
      color: $minsk;
      margin: 0px 0 10px;
    }
  }

  .order-history-filter {
    position: absolute;
    right: 35px;
    top: 30px;

    @media screen and (max-width: $mediumMinWidthFoundationBreak) {
      position: relative;
      right: 0px;
      top: 10px;
    }

    #ordercount {
      margin-top: 8px;
      display: inline-block;
      float: left;
      font-size: 13px;
      @include fontSize(1.3);
      font-weight: 700;
    }

    select {
      @include inputStyling;
      padding: 6px 50px 6px 5px;
      margin-left: 10px;
      @include fontSize(1.2);
      font-weight: 700;
      color: $black;
    }
  }

  .working {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.8;
    z-index: 100;

    &:before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      top: 50%;
      left: 50%;
      margin-left: -16px;
      margin-top: -16px;
      background-image: url('/images/general/loading.gif');
      position: absolute;
    }

    &.hidden {
      display: none;
    }
  }
}

#orderHistoryList {
  width: 100%;
  margin-top: 30px;
  @include clearfix();
  border-bottom: 2px solid $alto;

  .orderheader {
    width: 100%;
    float: left;
    @include fontSize(1.4);
    cursor: pointer;
    padding-bottom: 5px;
    border-top: 2px solid $alto;

    .heading {
      font-weight: bold;
      margin-top: 15px;

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        & {
          @include fontSize(1.3);
        }
      }
    }

    .orderdate {
      padding-left: 1%;
      width: 20%;
      float: left;
      clear: left;

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        width: 29%;
      }
    }

    .ordertotal {
      width: 20%;
      float: left;
    }

    .orderref {
      width: 20%;
      float: left;
    }

    .details {
      margin-right: 10px;
      height: 57px;
      width: auto;
      line-height: 57px;
      float: right;
      color: $mountainMeadow;
      font-weight: 700;
      @include fontSize(1.3);

      &:after {
        background-color: $mountainMeadow;
        @include icon($rs-icons-arrow-down, 18px, #fff);
        display: block;
        margin-top: 10px;
        margin-left: 20px;
        padding: 9px 8px 9px 9px;
        border-radius: 100%;
        float: right;
      }

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        & {
          font-size: 0px;
        }
      }
    }

    &.open .details {
      &:after {
        @include icon($rs-icons-arrow-up, 18px, #fff);
      }
    }
  }

  .orderitem {
    font-size: 13px;
    float: left;
    width: 100%;
    background-color: $concrete;

    .order-item {
      margin-top: 10px;
    }

    .header {
      font-weight: bold;
      @include fontSize(1.4);
      border-bottom: 2px solid white;
      margin-top: 10px;
      height: 45px;

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        & {
          @include fontSize(1.3);
        }
      }
    }

    .itemisbn {
      float: left;
      clear: left;
      width: 18%;
      padding: 1%;
      margin-left: 1%;

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        display: none;
      }
    }

    .itemtitle {
      float: left;
      width: 59%;
      padding: 1%;

      a {
        @include fontSize(1.3);
      }

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        width: 74%;
        margin-left: 1%;
      }

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        width: 60%;
      }
    }

    .itemtype {
      display: none;
    }

    .itemquantity {
      float: left;
      text-align: center;
      width: 9%;
      padding: 1%;

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        width: 11%;
      }

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        width: 15%;
      }
    }

    .itemprice {
      float: left;
      text-align: left;
      width: 12%;
      padding: 1%;

      @media screen and (max-width: $mediumMinWidthFoundationBreak) {
        width: 22%;
      }
    }

    .itemprice .error,
    .itemprice .basketError {
      margin-top: -10px;
      text-align: center;
    }

    .total {
      float: left;
      clear: left;
      width: 96%;
      text-align: right;
      padding: 2%;
      @include fontSize(1.4);
      font-weight: bold;
      box-sizing: content-box;
    }
  }
}
