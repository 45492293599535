

$browser-ignores-breakpoint: 2000px;
$breakpoint-large: 985px;
$breakpoint-medium-to-large: 950px;
$breakpoint-larger-than-medium: 900px;
$breakpoint-slightly-larger-than-medium: 830px;
$breakpoint-medium: 800px;
$breakpoint-smaller-than-medium: 700px;
$breakpoint-small-to-medium: 600px;
$breakpoint-larger-than-small: 480px;
$breakpoint-small: 450px;
$breakpoint-extra-small: 350px;


.examExpanders
{	
	padding-bottom: 25px;
	overflow: hidden;

	.subjectLevel
	{
		overflow: hidden;
		padding: 0 20px;
		margin-bottom: 15px;

		.content
		{
			background-color: $minsk;
			cursor: pointer;
			margin-bottom: 5px;
			float: left;
			width: 100%;
			position: relative;

			&:after {
				font-size: 18px;
				content: $rs-icons-arrow-right;
				color: #fff;
				font-family: 'rs-icons';
				position: absolute;
				right: 18px;
				top: 50%;
				margin-top: -9px;
			}

			& > div
			{
				color: #FFF;
			}

			.label
			{
				position: absolute;
				color: #fff;
				font-size: 16px;
				top: 50%;
				margin-top: -8px;
				left: 16px;
			

				@media screen and (max-width: $breakpoint-medium)
				{
					width: 59%;
				}

				@media screen and (max-width: $breakpoint-smaller-than-medium )
				{
					width: 78%;
				}

				@media screen and (max-width: $breakpoint-larger-than-small )
				{
					width: 69%;
				}

				@media screen and (max-width: $breakpoint-extra-small )
				{
					width: 59%;
				}
			}

			.details
			{
				padding-right: 50px;
				margin-right: 20px;
				height: 57px;
				line-height: 57px;
				font-size: 13px;
				float: right;
				background-repeat: no-repeat;
				background-position: right 22px;

				span {
					font-size: 13px;
				}	

				@media screen and (max-width: $breakpoint-smaller-than-medium ) 
				{
					font-size: 0;

					.the-s-on-items {
						font-size: 0px;
					}	
				}
			}

			&.open
			{
				border-bottom: 0 solid #eaeaea;
				&:after {
					content: $rs-icons-arrow-down;
				}

				.details
				{
					background-position: right -38px;
				}
			}
		}

		.subjects
		{
			clear: both;
			display: none;
			background: #fff;

			.subject
			{
				clear: both;

				.content
				{
					background: #eaeaea;
					height: 51px;
					border-bottom: 5px solid #eaeaea;
					margin-bottom: 1px;



					.details
					{
						background-repeat: no-repeat;
						background-position: right -95px;
					}

					&.open
					{
						margin-bottom: 0;

						.details
						{
							background-position: right -145px;
						}
					}
				}

				.optionsTable
				{
					margin-bottom: 1px;
					position: relative;
					clear: both;

					.optionsContent
					{
						border-left: 1px solid #e5e4e4;
						width: auto;
						
											

						.row
						{
							width: 100%;
						}
					}

					.row
					{
						margin: 0;
						float: left;
						font-size: 12px;
						color: #666;

						& > div
						{
							float: left;
						}

						.columnGroup
						{
							float: left;
							width: 89%;									
							padding-left: 0;
							margin: 0;
							position: relative;

							@media screen and (max-width: $breakpoint-small-to-medium)
							{
								width: 80%;
								border-right: 1px solid #e5e4e4;
							}

							@media screen and (min-width: $breakpoint-small-to-medium) and (max-width: $breakpoint-smaller-than-medium)
							{
								width: 80%;
							}

							@media screen and (max-width: $breakpoint-small)
							{
								width: 70%;
							}

							.column
							{

								@media screen and (max-width: $breakpoint-small-to-medium)
								{
									float: none;
									display: block;
									border: 0;
									width: auto !important;
								}

								&.notes-column {
									padding-left: 4px;
									padding-right: 4px;
								}

								&.more-info-column {
									text-align: center;
									a {
										display: inline-block;
										float: none;
									}
								}
							}
						}

						.column
						{
							padding: 0;
							border-right: 1px solid #e5e4e4;
							list-style: none;
							float: left;							

							&.column:first-child
							{
								width: 35%;

								@media screen and (min-width: $breakpoint-small-to-medium) and (max-width: $breakpoint-smaller-than-medium)
								{
									width: 33%;
								}

                                @media screen and (max-width: $breakpoint-small-to-medium){
                                    height: auto !important;
                                    .tests {
                                        position: static;
                                        transform: none;
                                    }
                                }

								& + .column
								{
									width: 51%;
									border-right: 0;
									border-left: 1px solid #e5e4e4;								
									margin-left: -1px;

									@media screen and (min-width: $breakpoint-smaller-than-medium) and (max-width: $breakpoint-larger-than-medium)
									{
										width: 47%;
									}

									@media screen and (min-width: $breakpoint-small-to-medium) and (max-width: $breakpoint-smaller-than-medium)
									{
										width: 45%;
									}


									& + .column
									{
										width: 95px;
										position: absolute;
										height: 100%;
										right: 0;
										top: 0;
										border-left: 1px solid #e5e4e4;

										@media screen and (max-width: $breakpoint-small-to-medium)
										{
											float: none;
											position: static;
											border-left: none;

										}

									}
									
								}
							}

							&.last
							{
								padding-top: 4px;
								border-right: 0;
								width: 11%;
								overflow: hidden;
								float: right;

								@media screen and (max-width: $breakpoint-smaller-than-medium)
								{
									width: 75px;
								}

								.tests
								{
									line-height: 16px;
									padding: 4px 2px 3px;
								}
							}
						}

						&.white {
							background: #FFF;
						}

						.tests
						{
							padding: 19px 2px 16px;
							float: left;
							display: block;

							@media screen and (max-width: $breakpoint-small-to-medium)
							{
								float: none;
								max-height: 100%;
								line-height: normal;
								padding: 10px;
							}

							.test
							{
								float: left;
								width: 52px;
								text-align: center;

								input
								{
									width: 50px;
									text-align: center;
								}
							}

						}
					}


					
				}
			}

			.clearSubject
			{
				float: left;
				width: 100%;

				.label
				{
					float: left;
					margin: 10px 0 10px 10px;
					font-size: 13px;
				}
			}
		}
	}

    &.magazine
    {
        .subjectLevel .subjects .subject .optionsTable .row .column.column:first-child+.column+.column{
            width:auto !important;
        }
        .preOrder{
            color: #6C4099;
        }
        .basketError{
            clear: both;
            margin-left: 20px;
        }
        .clearSelectedSubscriptions{
            float  :right;
            font-size: 13px;
            margin-bottom: 5px;
        }
        overflow: visible;
        .subjectLevel .content {
            background-color: #6C4099;
        }
        .optionsTable {
            background-color: red;
            .columnGroup{
                width:100% !important;
                @media screen and (max-width: $breakpoint-medium) {
                    border-left: 1px solid #ccc;
                }
            }
            .column
		    {
			    list-style: none;
                position: relative;
			    float: left;	
                width:40%;
                height: 100px !important;
                border-left: 1px solid #cccccc !important;
                border-right: none !important;
                @media screen and (max-width: $breakpoint-medium) {
                    border-left: 0 !important;
                    border-right: 0 !important;
                }
                
                &.nopad{
                    .tests{
                        padding: 5px 20px !important;
                        @media screen and (max-width: $breakpoint-medium) {
                            position: static !important;
                            transform: none !important;
                        }
                    }
                }					

                &:last-child {
                    .tests{
                            width: 45% !important;

                            @media screen and (max-width: $breakpoint-medium) {
                                width: 100% !important;
                            }
                        }
                        
                }

                &.smallColumn{
                    @media screen and (max-width: $breakpoint-medium) {
                        height: 35px !important;
                    }
                }

                &.column:first-child {
                    width: 35% !important;
                    border-left: none !important;
                    &+.column{
                        width: 15% !important;
                        & + .column {
                            position: static !important;
                        }
                    }
                    
                    +.column {
                    @media screen and (min-width: $breakpoint-smaller-than-medium) and (max-width: $breakpoint-larger-than-medium){
                        a {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            padding: 0 !important;
                        }
                    }
                }
                       
                }
            }
            .tests{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                .test{
                    width: auto !important;
                }
                .startOfGroup{
                    width: 100%;
                    @media screen and (max-width: $breakpoint-medium) {
                        width: 90%;
                    }

                    &:after {
                        content: "";
                        display: table;
                        clear: both;
                        
                    }
                    &.children0{
                            .field{
                                width: 100%;
                            }
                    }
                    &.children1{
                            .field{
                                width: 100%;
                            }
                    }
                    &.children2{
                            .field{
                                width: 50%;
                            }
                    }
                    &.children3{
                            .field{
                                width: 33.3%;
                                .p{
                                     @media screen and (max-width: $breakpoint-medium) {
                                        display: inline-block;
                                        vertical-align: middle;
                                        max-width: 40px;
                                    }
                                }
                            }
                    }
                    &.children4{
                            .field{
                                width: 25%;
                            }
                    }
                        
                }
                .field {
                    float: left;
                    .test-amount-label {
                        display: block;
                        color: #0075C3;
                        font-weight: bold;
                    }
                    input {
                        display: inline;
                    }
                    p {
                        display: inline;
                        @media screen and (max-width: $breakpoint-medium) {
                            display: inline-block;
                            vertical-align: middle;
                            
                        }
                    }
                    input[type="number"] {
                        max-width: 30px !important;
                        @media screen and (max-width: $breakpoint-medium) {
                            
                            display: inline-block;
                        }

                    }
                }
            }
            .row{
            	margin: 0;
                @media screen and (max-width: $breakpoint-medium) {
                        .columnGroup{
                            border: 0 !important;
                        }
                        padding: 20px 0;
                        border-left: 1px solid #ccc;
                        border-right: 1px solid #ccc;
                }
            .column{
                    width:36%;
  
                }
            }        
        }
       
    }

	.clearAllSubjects
	{
		//overflow: hidden;
		margin: 0 20px;

		.label
		{
			float: left;
			font-size: 13px;
			margin: 10px 0;
		}		
	}

	.addToBasketButton {
		float: right;
		background-color: #19B5A5;
		display: inline-block;
		padding: 10px 25px;
		margin-bottom: 0;
		font-size: 14px;
		line-height: 1.428571429;
		text-align: center;
		vertical-align: middle;
		position: relative;
		cursor: pointer;
		border: 0 none;
		border-radius: 15px;
		white-space: nowrap;
		-webkit-user-select: none;
		color: #fff;
	}
}
