.latest-blog-posts-home {
	&.latest-blog-posts-subject-page {
		width: 50%;
		// min-height: 0px!important;
		.more-blog-posts {
			display: none;
		}
	}

	min-height: 472px;
	display: inline-block;
	width: 33%;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 43px;
	vertical-align: top;

	@media screen and (max-width: 768px) {
		width: 100%!important;
		min-height: 409px;
	}

	.latest-blog-posts-wrapper {
		display: block;
		vertical-align: top;
		position: relative;
		padding-left: 120px;
		padding-right: 5px;
		margin-bottom: 7px;
		min-height: 91px;
	}

	p {
		margin-bottom: 0px;
	}


	h2 {
		border-bottom: 1px solid $minsk;
		padding-bottom: 11px;
		font-size: 2.8rem;
		text-align: left;
	}

	.latest-blog-posts-image {
		position: absolute;
    	top: 0;
    	left: 0;
    	height: 95px;
    	width: 120px;
    	overflow: hidden;

    	img {
    		position: absolute;
    		left: 0;
    		right: 0;
    	}
	}

	.latest-blog-posts-title {
		line-height: 1.4;
		color: $brand-primary;
		font-weight: bold;
	}

	.latest-blog-posts-text {
		display: block;
		margin-left: 10px;
		text-align: left;
		display: inline-block;
		line-height: 1.4;
		color: $midGray;
		font-weight: bold;
	}

	.latest-blog-posts-date {
		color: $java;
		font-size: 1.3rem;
		font-weight: normal;
	}

	.latest-blog-posts-widget {
		padding-top: 10px;
	}

	.more-blog-posts {
		position: absolute;
		bottom: 10px;
		font-weight: bold;
		&:after {
			padding-left: 0.31em;
			content: '\000BB';
			display: inline-block;
		}

		@media screen and (max-width: 768px) {
			position: static;
		}
	}

	.latest-blog-posts-list {
		@media screen and (max-width: 768px) {
			padding-bottom: 20px;
		}
	} 
}

