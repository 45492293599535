$brand-primary: #252872;
$brand-secondary: #0b2d46;
$brand-tertiary: #e6eaea;
$brand-light-blue: #c6e9f5;
$brand-white: #fff;
$brand-black: #000;
$brand-yellow: #f28c00;

$site-wide-legacy: 980px;
$site-wide: 1536px;
$viewport-wide: 100vw;

// Product listing
$pl-gap: 20px;

$screen-lg: 1440px;
$screen-md: 991px;
$screen-mobile: 768px;
$screen-sm: 375px;
$screen-xs: 340px;

// Listing stuff.
$color-dl: #c2002b;
$color-conf: #167900; //#7d8b00;
$color-pam: #7b00d3;
$color-book: #dd5a00;
$color-digital-download: #9e005d;

// needed to add these due to build errors from shared components
$color-boost: #36448e;
$color-boost-blue: #0092ca;
$color-boost-green: #649f41;
$teejay-red: #e5292b;
$teejay-blue: #19d4d1;
$parenthub-pink: #e20d80;
$parenthub-blue: #66cac7;
$brand-maroon: #b3154f;
$eduu-yellow: #f2b23d;
$eduu-blue: #0f1473;
