html {
  scroll-behavior: smooth;
}

.pricing-grid {
  padding: 50px 20px;

  @media screen and (min-width: 992px) {
    padding: 100px 30px;
  }

  &--two-one {
    @media screen and (min-width: 992px) {
      display: flex;
      flex-wrap: wrap;

      @supports (display: grid) {
        display: grid;
        grid-gap: 34px;
        grid-template-columns: 2fr 1fr;
      }
    }

    > div {
      &:nth-child(1) {
        order: 1;
        flex-basis: calc(66.666% - 16px);
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(3) {
        order: 2;
        flex-basis: calc(33.333% - 16px);
        transition-delay: 200ms;
      }

      &:nth-child(4) {
        order: 4;

        .pricing-grid__card {
          transition-delay: 400ms;
        }
      }
    }
  }

  &--centered {
    @media screen and (min-width: 992px) {
      margin: 0 -16px;
    }
  }

  &__column {
    @media screen and (min-width: 992px) {
      .pricing-grid--two-one & {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        &:nth-child(1) {
          flex-basis: calc(66.666% - 16px);
          padding-left: 16px;

          @supports (display: grid) {
            padding-left: 0;
          }
        }

        &:nth-child(2) {
          flex-basis: calc(33.333% - 16px);
          padding-right: 16px;
          flex-direction: column;

          @supports (display: grid) {
            padding-right: 0;
          }
        }
      }

      .pricing-grid--centered & {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &-text {
      width: 100%;
      text-align: center;
      margin-bottom: 50px;
      opacity: 0;
      transform: translateY(40px);
      transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;

      .animated & {
        opacity: 1;
        transform: translateY(0px);
      }

      @media screen and (min-width: 992px) {
        margin-bottom: 0;

        .pricing-grid--two-one & {
          min-height: 120px;
        }

        .pricing-grid--centered & {
          margin-bottom: 100px;
        }
      }

      h2 {
        @include fontSize(3);
        font-weight: bold;
        margin: 0;
      }

      small {
        display: block;
        @include fontSize(1.8);
        margin-top: 6px;
      }
    }
  }

  &--two-one .pricing-grid__column {
    @media screen and (min-width: 992px) {
      flex-basis: calc(33.333% - 16px);

      .pricing-grid__card {
        flex: 1;
      }

      &:nth-child(2) {
        flex-direction: row;
        flex-basis: calc(66.666% - 16px);
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding: 44px 34px;
    border-radius: 5px;
    margin-bottom: 90px;
    opacity: 0;
    transform: translateX(-40px);
    transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;

    .animated & {
      opacity: 1;
      transform: translateX(0px);

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 200ms;
        }
      }

      > * {
        opacity: 1;
        transform: translateY(0px) scale(1);
      }
    }

    > * {
      opacity: 0;
      transform: translateY(60px) scale(1.15);
      transition: opacity 350ms ease-in-out 300ms,
        transform 350ms ease-in-out 300ms;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 150ms;
        }
      }
    }

    @media screen and (min-width: 992px) {
      margin-bottom: 0;

      .pricing-grid--centered & {
        flex-basis: calc(33.333% - 32px);
        margin: 0 16px;
      }
    }

    &--mark {
      background-color: $minsk;

      .btn {
        background-color: $tiaMaria;

        &:hover {
          background-color: $minsk;
        }
      }
    }

    &--mark-plus {
      background-color: $tiaMaria;

      .btn {
        background-color: $minsk;

        &:hover {
          background-color: $tiaMaria;
        }
      }

      .pricing-grid__card-includes-list ul li.include-tick:after {
        background: url('/sitefiles/src/img/rising-stars/icon-tick-blue.svg')
          no-repeat center;
        background-size: contain;
      }
    }

    &--shine {
      background-color: #af1280;

      .btn {
        background-color: $tiaMaria;

        &:hover {
          background-color: #af1280;
        }
      }

      @media screen and (max-width: 991px) {
        margin-top: 100px;
      }
    }

    .pricing-grid--two-one .pricing-grid__column:nth-child(1) & {
      @media screen and (min-width: 992px) {
        width: 50%;
      }

      &:nth-child(2) {
        position: relative;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: -5px;
          width: 5px;
          height: 100%;
          background-color: inherit;
        }
      }
    }

    .pricing-grid--two-one .pricing-grid__column:nth-child(2) & {
      @media screen and (min-width: 992px) {
        width: 100%;
        flex: 1;
      }
    }

    &-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: inherit;
      @include fontSize(1.4);
      font-weight: bold;
      color: $brand-white;
      margin-top: -88px;
      margin-bottom: 44px;
      height: 45px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      @media screen and (min-width: 992px) {
        @include fontSize(1.8);
      }
    }

    &-logo {
      display: flex;
      margin-bottom: 46px;
      height: 62px;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
        padding: 0 60px;
      }

      img {
        display: inline-block;
      }
    }

    &-dropdown {
      margin-bottom: 24px;

      label {
        display: block;
        @include fontSize(1.4);
        font-weight: bold;
        color: $brand-white;
        margin-bottom: 4px;
      }

      select {
        display: block;
        width: 100%;
        height: 50px;
        padding: 0 16px;
        padding-right: 40px;
        color: #818285;
        @include fontSize(1.6);
        font-family: 'Oxygen';
        border: none;
        box-shadow: none;
        background: $brand-white
          url('/sitefiles/src/img/rising-stars/icon-caret-down.svg') no-repeat
          right 16px center;

        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';

        &::-ms-expand {
          display: none;
        }
      }
    }

    &-sub {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      padding: 10px;
      border-radius: 5px;
      background: rgba($brand-white, 0.3);
      min-height: 50px;

      @media screen and (min-width: 992px) {
        min-height: 70px;
      }

      > small {
        display: block;
        @include fontSize(1.4);
        font-weight: bold;
        margin-bottom: 4px;
      }

      .pricing-grid__card--mark & {
        strong {
          @include fontSize(2);
          line-height: 20px;
          font-weight: bold;
          color: $brand-white;

          @media screen and (min-width: 992px) {
            @include fontSize(3);
            line-height: 30px;
          }
        }
      }

      .pricing-grid__card--mark-plus & {
        small {
          color: $minsk;
        }

        strong {
          @include fontSize(2);
          line-height: 20px;
          font-weight: bold;
          color: $minsk;

          @media screen and (min-width: 992px) {
            @include fontSize(3);
            line-height: 30px;
          }

          small {
            @include fontSize(1.4);
            vertical-align: text-bottom;
          }
        }
      }

      .pricing-grid__card--shine & {
        small {
          color: $brand-white;
        }

        strong {
          @include fontSize(2);
          line-height: 20px;
          font-weight: bold;
          color: $brand-white;

          @media screen and (min-width: 992px) {
            @include fontSize(3);
            line-height: 30px;
          }

          small {
            @include fontSize(1.4);
            vertical-align: text-bottom;
          }
        }
      }
    }

    &-text {
      flex: 1;
      margin-bottom: 30px;

      // IE only
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: auto;
      }

      .pricing-grid__card--shine & {
        flex: 0;

        // IE only
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          padding-right: 16px;
          flex: auto;
        }
      }

      p {
        @include fontSize(1.4);
        font-weight: bold;
        color: $brand-white;
      }
    }

    &-includes {
      margin-bottom: 30px;

      .pricing-grid__card--shine & {
        flex: 1;

        // IE only
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          flex: auto;
        }
      }

      &-heading {
        @include fontSize(2);
        font-weight: bold;
        color: $brand-white;
        margin-bottom: 8px;

        &:after {
          content: ':';
          display: inline;
        }
      }

      &-list {
        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            position: relative;
            display: block;
            margin: 4px 0;
            padding: 4px 40px 4px 0;
            color: $brand-white;
            @include fontSize(1.6);

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              right: 0;
              width: 16px;
              height: 16px;
              transform: translateY(-50%);
            }

            &.include-tick:after {
              background: url('/sitefiles/src/img/rising-stars/icon-tick-orange.svg')
                no-repeat center;
              background-size: contain;
            }

            &.include-cross:after {
              background: url('/sitefiles/src/img/rising-stars/icon-cross.svg')
                no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }

    .btn {
      cursor: pointer;
      display: block;
      width: 100%;
      padding: 16px 10px;
      @include fontSize(1.8);
      font-weight: bold;
      color: $brand-white;
      border-radius: 3px;
      border: none;
      margin-top: auto;
      border: 2px solid transparent;
      transition: all 0.4s ease-in-out, opacity 350ms ease-in-out 750ms,
        transform 350ms ease-in-out 750ms !important;

      &:hover {
        border-color: $brand-white;
      }

      &[disabled] {
        cursor: not-allowed;
        background-color: #bcbdc0;
        border-color: transparent;
      }
    }
  }

  &__trial {
    small {
      display: block;
      text-align: center;
      @include fontSize(1.6);
      font-weight: bold;
      margin: 10px 0;
      color: $brand-white;
    }
  }
}
