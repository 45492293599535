.free-stuff-archive-page {
	padding: 60px 0;

	.free-stuff-archive-list {
		position: relative;
		.spinner {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: #fff;
			opacity: 0.8;
			z-index: 100;

			&:before {
				content: '';
				display: block;
				width: 32px;
				height: 32px;
				top: 50%;
				left: 50%;
				margin-left: -16px;
				margin-top: -16px;
				background-image: url(/images/general/loading.gif);
				position: absolute;
				
			}
		}
	}

	.free-stuff-archive-list {
		@include justifyLayout();
	}

	a.free-stuff-page-archive-listing {
		width: 230px;
		display: inline-block;
		vertical-align: top;
		height: 154px;
		position: relative;
		padding-left: 69px;
		padding-bottom: 20px; 
		padding-right: 5px;

		.free-stuff-page-icon-cont {
			position: absolute;
			top: 0;
			left: 0;
			text-align: right;
		}

		.free-stuff-page-text-cont {
			display: block;

			.free-stuff-page-title {
				text-align: left;
				display: inline-block;
				line-height: 1.4;
				color: #6a6a6d;
			}

		}

		.free-stuff-page-cta {
			display: inline-block;
			padding-top: 7px;
			color: #ff0048;
			font-family: 'Oxygen', sans-serif;

			&:after {
				padding-left: 0.31em;
				content: '\000BB';
				display: inline-block;
			}
		}

		&:hover {
			.free-stuff-page-cta {
				text-decoration: underline;
			}
		}

		img {
			width: 56px;
		}
	}
}

.free-stuff-filter-control {
	.checkbox-list-title {
		font-size: 21px;
		padding-top: 10px;
		padding-bottom: 16px;
	}


	.filter-checkboxes {
		margin-top: 20px;
		background-color: #ff6934;
		color: #fff;
		padding: 10px;
		padding-bottom: 20px;
	}

	.checkbox-cont {
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		position: relative;
		padding-bottom: 3px;

		label {
			display: block;
			padding-left: 23px;
			padding-bottom: 4px;
		}

		input[type=checkbox] {
			padding-top: 1px;
			position: absolute;
			top: 0px;
			left: 0px;
		}
	}

	.subject-checkbox-label {
		font-size: 15px;
	}

	.checkbox-list {
		-moz-column-count: 3;
		-moz-column-gap: 2px;
		-ms-column-count: 3;
		-ms-column-gap: 3px;
		-webkit-column-count: 3;
		-webkit-column-gap: 2px;
		column-count: 3;
		column-gap: 2px;

		@media screen and (max-width: 572px) {
			-moz-column-count: 2;
			-ms-column-count: 2;
			-webkit-column-count: 2;
			column-count: 2;
		}

		@media screen and (max-width: 385px) {
			-moz-column-count: 1;
			-ms-column-count: 1;
			-webkit-column-count: 1;
			column-count: 1;
		}
	}

	.dropdown-cont {
		padding-top: 15px;
		padding-bottom: 20px;
		label {
			color: $minsk;
			text-align: left;
			font-size: 17px;
			padding-right: 10px;
		}

		select {
			padding: 4px;
			border-radius: 4px;
		}
	}
}

