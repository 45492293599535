* {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

h1 {
  color: #006d77;
  margin: 0 0 36px;
}

h2 {
  color: #006d77;
  margin: 20px 0 10px;
}

h3 {
  color: #006d77;
  margin: 20px 0 10px;
}

h4 {
  color: #006d77;
  font-weight: bold;
  margin: 10px 0;
}

h5 {
  color: #006d77;
  margin: 0 0 20px;
}

h6 {
  color: #006d77;
  font-weight: bold;
  margin: 0 0 20px;
}

p {
  color: $mineShaft;
  line-height: 23px;
  margin: 0 0 20px;
}

a {
  color: #006d77;

  &:link,
  &:visited {
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

strong {
  font-weight: bold;
}
