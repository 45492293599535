@mixin inputStyling {
	@include fontSize(1.4);
    line-height: 1.42857143;
    color: $emperor;
    background-color: $white;
    border: 1px solid $silver;
    border-radius: 4px;
    vertical-align: middle;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    padding: 6px 12px;
}