﻿.side-navigation {
    text-align: left;
	background-color: $mountainMeadow2;

    .side-navigation-title {
        color: #fff;
        font-size: 24px;
        border-bottom: 1px solid #fff;
        padding: 15px;
		margin-top: 0px;
    }

	ul {
		background-color: $mountainMeadow2;
        padding: 0;
        list-style-type: none;
        padding-bottom: 30px;
		line-height: 23px;
		@include fontSize(1.5); 
		margin-bottom: 10px;
    }

    li a {
        display: block;
        color: #fff;
        padding: 2px 15px;

        &.active,
        &:hover,
        &:active,
		&:focus {
            background: $persianGreen;
			text-decoration: none;
        }
    }

    li.HighLighted {
    	background: $persianGreen;

    	ul {
    		margin-bottom: 0px;
    		padding-bottom: 0px;

    		li a {
                display: block;
    			padding-left: 30px;
    		}
		}
	}

	&.colour2 {
		background-color: $burningOrange;

		ul {
			background-color: $burningOrange;
		}

		li a {
			&.active,
			&:hover,
			&:active,
			&:focus {
				background: $tiaMaria;
			}
		}

		li.HighLighted {
			background: $tiaMaria;
		}
	}
}